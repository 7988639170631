//React imports
import React, { useState, useEffect } from 'react';

//---

//CSS imports
import './ConnectorSelection.css'
//---

//PrimeReact imports
import { Divider } from 'primereact/divider';
//---

//Vendors imports
//---

//Components imports
import ConnectionsHorizontalInventory from '../connection/ConnectionsHorizontalInventory';
import ConnectorsStore from './ConnectorsStore';
//---

//Data requests imports
//---

const ConnectorSelection = ({ connections, availableConnectors, connectorType, storedSelectedConnection, onSelectConnector }) => {

    const [selectedConnection, setSelectedConnection] = useState(storedSelectedConnection)
    const [filteredConnectors, setFilteredConnectors] = useState([]);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            setFilteredConnectors(availableConnectors[connectorType])
        }

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableConnectors]);

    const onSelectConnection = (connection) => {
        setSelectedConnection(connection);
        setFilteredConnectors(availableConnectors[connectorType].sort((aAc, bAc) => {
            if (aAc['connection-type'] === connection['connection-type']) {
                if (bAc['connection-type'] === connection['connection-type']) {
                    if (aAc.name < bAc.name) {
                        return -1
                    } else if (aAc.name < bAc.name) {
                        return 1
                    }
                    return 0
                }
                return -1;
            }
            return 0
        }));
    }

    return (
        <div className='connector-selection'>
            <ConnectionsHorizontalInventory
                connections={connections}
                selectedConnection={selectedConnection}
                onSelectConnection={onSelectConnection}
            />
            <Divider style={{ margin: '0.5rem auto 1rem auto', with: '100%', maxWidth: '800px'}} />
            <ConnectorsStore
                connectors={filteredConnectors}
                onSelectConnector={(connector) => onSelectConnector(selectedConnection, connector)}
                compatibleConnectionType={selectedConnection['connection-type']}
                mode={connectorType === 'available-taps' ? 'reader' : 'writer'}
            />
        </div>
    );
};



export default ConnectorSelection;
