//React imports
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
//---

//CSS imports
import './Artefacts.css'
//---

//PrimeReact imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Chip } from 'primereact/chip';
//---

//Vendors imports
import axios from 'axios';
import cronstrue from 'cronstrue';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import { GlobalAecProjectStateContext } from '../../components/GlobalAecProjectStateProvider';
//---

//Data requests imports
import {
    listArtefacts,
    addSchedulesToArtefacts
} from '../../data/ArtefactData';
import {
    listTaskStatuses
} from '../../data/TaskData';
import {
    getLastSelectedTaskEngine,
    setLastSelectedTaskEngine
} from '../../data/AppLocalData';
//---

const Artefacts = ({ projectName }) => {
    const { aecProject } = useContext(GlobalAecProjectStateContext);

    const { showNotification } = useNotification();

    const cancelTokenSource = axios.CancelToken.source();

    const dt = useRef(null);

    const [artefacts, setArtefacts] = useState([])

    const [globalFilter, setGlobalFilter] = useState('');
    const globalFilterInputRef = useRef(null);

    const [selectedArtefactKinds, setSelectedArtefactKinds] = useState(null);
    const [uniqueArtefactKinds, setUniqueArtefactKinds] = useState([]);

    const [refreshIsLoading, setRefreshIsLoading] = useState(false);

    const [selectedEngine, setSelectedEngine] = useState(() => {
        return getLastSelectedTaskEngine()
    })

    useEffect(() => {
        if (aecProject.name) {
            listArtefactsCtlr(selectedEngine);
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aecProject]);

    useEffect(() => {
        if (aecProject.name) {
            listArtefactsCtlr(selectedEngine)
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEngine]);

    const listArtefactsCtlr = (engine) => {
        setRefreshIsLoading(true);
        listArtefacts(cancelTokenSource, projectName).then(
            aData => {
                if (aData.artefacts) {
                    createUniqueArtefactKinds(aData.artefacts);

                    listTaskStatuses(cancelTokenSource, projectName, engine, 'cron').then(
                        tData => {
                            if (tData.taskManifests) {
                                let _artefacts = []
                                _artefacts = addSchedulesToArtefacts(aData.artefacts, tData.taskManifests)

                                setArtefacts(_artefacts);
                                setRefreshIsLoading(false);
                            }
                        },
                        errorMessage => {
                            let _artefacts = []
                            _artefacts = addSchedulesToArtefacts(aData.artefacts, [])
                            setArtefacts(_artefacts);
                            showNotification('error', 'Error', `cannot get triggers; ${errorMessage}`, 6000)
                            setRefreshIsLoading(false);
                        }
                    );
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
                setRefreshIsLoading(false);
            }
        );
    }

    const createUniqueArtefactKinds = (artefacts) => {
        let uniqueArtefactKinds = [...new Map(artefacts.map(item =>
            [item.kind,
            { 'kind': item.kind }])).values()]
        setUniqueArtefactKinds(uniqueArtefactKinds)
    }

    const onArtefactKindsChange = (e) => {
        dt.current.filter(e.value, 'kind', 'in');
        setSelectedArtefactKinds(e.value);
    }

    const onGlobalFilterSearch = (globalFilterValue) => {
        setGlobalFilter(globalFilterValue);
        dt.current.filter(globalFilterValue, 'global', 'contains');
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onGlobalFilterSearch(globalFilterInputRef.current.value);
        }
    }

    const reset = () => {
        setSelectedArtefactKinds(null)
        setGlobalFilter('');
        globalFilterInputRef.current.value = '';
        dt.current.reset();
    }

    const getTriggerHumanReadable = (cronExpression) => {
        if (cronExpression) {
            try {
                return cronstrue.toString(cronExpression)
            } catch {
                return `invalid expression (${cronExpression})`
            }
        }

        return 'empty cron expression'
    }

    const renderTriggers = (rowData) => {
        if (rowData.triggers) {
            return rowData.triggers.map((trigger, i) => (
                <Chip key={`${rowData.name}-${i}`} label={getTriggerHumanReadable(trigger)} className="p-m-1 trigger-chip" />
            ));
        }

        return null;
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Name</span>
                <Link className='resource-link' to={`/${projectName}/artefacts/${rowData.name}`}>
                    {rowData.name}
                </Link>
            </React.Fragment>
        );
    }

    const descriptionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Description</span>
                <div className="description">{rowData.description}</div>
            </React.Fragment>
        );
    }

    const artefactKindBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Kind</span>
                <span className="image-text">{rowData.kind}</span>
            </React.Fragment>
        );
    }

    const nextTriggerBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Next trigger</span>
                <span className="image-text">{rowData.nextTrigger}</span>
            </React.Fragment>
        );
    }

    const triggersBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Triggers</span>
                <div className='p-d-flex p-ai-center p-flex-wrap'>
                    {renderTriggers(rowData)}
                </div>
            </React.Fragment>
        );
    }

    const kindsItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <span className="image-text">{option.kind}</span>
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <Link to={`/${projectName}/artefact/create`}>
                <Button label="New" icon="pi pi-plus" />
            </Link>
            <div>
                <div className='select-engine'>
                    <span className='p-text-bold'>Engine : </span>
                    <Dropdown
                        name='engine'
                        className='p-mr-2'
                        placeholder='engine'
                        value={selectedEngine}
                        options={aecProject.eventSchedulerTaskEngines}
                        onChange={(e) => {
                            setSelectedEngine(e.value);
                            setLastSelectedTaskEngine(e.value);
                        }}
                    />
                </div>
                <span className="p-input-icon-left p-mr-2">
                    <i className="pi pi-search" />
                    <InputText type="search" ref={globalFilterInputRef} placeholder="Global Search" className="searchbox" onKeyDown={handleKeyDown} />
                    <Button type="button" icon="pi pi-search" className="p-button-outlined p-ml-2" onClick={() => onGlobalFilterSearch(globalFilterInputRef.current.value)} />
                </span>
                <Button type="button" label="Clear" className="p-button-outlined p-mr-2" icon="pi pi-filter-slash" onClick={reset} />
                <Button type="button" label="Refresh" className="p-button-outlined" icon="pi pi-replay" onClick={() => listArtefactsCtlr(selectedEngine)} loading={refreshIsLoading} />
            </div>
        </div>
    );

    const artefactKindsFilter = <MultiSelect
        value={selectedArtefactKinds} options={uniqueArtefactKinds}
        itemTemplate={kindsItemTemplate} onChange={onArtefactKindsChange}
        optionLabel="kind" optionValue="kind" placeholder="All" className="p-column-filter"
    />

    return (
        <div className="artefacts">
            <div className="card">
                <DataTable ref={dt} value={artefacts} paginator rows={10}
                    header={header} className="p-datatable-artefacts"
                    selectionMode="single"
                    loading={refreshIsLoading}
                    responsiveLayout="scroll"
                    globalFilter={globalFilter} emptyMessage="No artefacts found.">

                    <Column
                        field="name" header="Name" body={nameBodyTemplate}
                        sortable filter filterPlaceholder="name" filterMatchMode="contains"
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="description" header="Description"
                        body={descriptionBodyTemplate} sortable filter
                        filterPlaceholder="description" filterMatchMode="contains"
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="kind" header="Kind"
                        body={artefactKindBodyTemplate} sortable filter
                        filterPlaceholder="kind" filterElement={artefactKindsFilter}
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="nextTrigger" header="Next trigger"
                        body={nextTriggerBodyTemplate} sortable filter
                        filterPlaceholder="next trigger" filterMatchMode="contains"
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="triggers" header="Triggers"
                        body={triggersBodyTemplate}
                        showFilterMatchModes={false} showFilterMenuOptions={false}
                        className="triggers-column" />
                </DataTable>
            </div>
        </div>
    );
};

export default Artefacts;
