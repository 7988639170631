//React imports
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
//---

//CSS imports
import './BundleStatus.css'
//---

//PrimeReact imports
import { Fieldset } from 'primereact/fieldset';
import { Divider } from 'primereact/divider';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chip } from 'primereact/chip';
import { Button } from 'primereact/button';
//---

//Vendors imports
import axios from 'axios';
//---


//Components imports
import { useNotification } from '../../components/NotificationProvider';
import { GlobalAecProjectStateContext } from '../../components/GlobalAecProjectStateProvider';
//---

//Utils imports
import { formatFullDate } from '../../utils/Date';
//---

//Data requests imports
import {
    getBundleStatus,
    processBundleStatusTasksList
} from '../../data/BundleData';
import { defaultBundleStatus } from '../../data/DefaultStates';
//---

const BundleStatus = ({ projectName, bundleStatusRedisID }) => {
    const { showNotification } = useNotification();

    const { aecProject } = useContext(GlobalAecProjectStateContext);

    const cancelTokenSource = axios.CancelToken.source();

    const [bundleStatus, setBundleStatus] = useState(defaultBundleStatus);

    const [refreshIsLoading, setRefreshIsLoading] = useState(false);

    useEffect(() => {
        if (aecProject.name) {
            getBundleStatusCtlr(bundleStatusRedisID);
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aecProject]);

    const getBundleStatusCtlr = (bundleStatusRedisID) => {
        setRefreshIsLoading(true);
        getBundleStatus(cancelTokenSource, bundleStatusRedisID).then(
            data => {
                if (data.bundleStatus) {
                    let bundleStatusTasks = []

                    if (data.bundleStatus.tasks) {
                        bundleStatusTasks = processBundleStatusTasksList(data.bundleStatus.tasks)
                    }

                    let _bundleStatus = data.bundleStatus
                    _bundleStatus.tasks = bundleStatusTasks

                    setBundleStatus(_bundleStatus);
                    setRefreshIsLoading(false);
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
                setRefreshIsLoading(false);
            }
        );
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Link className='resource-link' to={`/${projectName}/tasks/${rowData['redis-id']}`}>
                    {rowData.name}
                </Link>
            </React.Fragment>
        );
    }

    const renderDependencies = (dependency) => {
        if (dependency) {
            if (dependency.type === 'tasks') {
                if (dependency['tasks-dependencies']) {
                    if (dependency['tasks-dependencies'].tasks) {
                        return dependency['tasks-dependencies'].tasks.map((task, i) => (
                            <Chip key={`${task.name}-${i}`} label={task.name} className="p-m-1 custom-chip" />
                        ));
                    }
                }
            } else if (dependency.type === 'files') {
                return null;
            }
        }

        return null;
    }

    const dependenciesBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='p-d-flex p-ai-center p-flex-wrap'>
                    {renderDependencies(rowData.dependency)}
                </div>
            </React.Fragment>
        );
    }

    const startTimeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div>{rowData.customStartTime}</div>
            </React.Fragment>
        );
    }

    const completionTimeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div>{rowData.customCompletionTime}</div>
            </React.Fragment>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div>{rowData.status}</div>
            </React.Fragment>
        );
    }

    return (
        <div className='bundle-status p-mb-2'>
            <div className='bundle-status-details p-mb-2'>
                <Fieldset className='p-ml-0 p-mr-0 container' legend={bundleStatus.id}>
                    <h3 className='title-color'>Details</h3>
                    <p><span className="p-text-bold">Start time : </span>{formatFullDate(bundleStatus['start-time'])}</p>
                    <p><span className="p-text-bold">Completion time : </span>{formatFullDate(bundleStatus['completion-time'])}</p>
                    <p><span className="p-text-bold">Status : </span>{bundleStatus.status}</p>
                    <p><span className="p-text-bold">Step : </span>{bundleStatus.step}</p>
                    <p>
                        <span className="p-text-bold">Error : </span>
                        <span className='error'>{bundleStatus.error}</span>
                    </p>
                    <p><span className="p-text-bold">Engine : </span>{bundleStatus['engine-setup'].engine}</p>

                    <Divider />

                    <Button
                        className='p-button-outlined'
                        label='Refresh'
                        icon="pi pi-replay"
                        onClick={() => getBundleStatusCtlr(bundleStatusRedisID)}
                        loading={refreshIsLoading}
                    />

                    <Divider />

                    <h3 className='title-color'>Tasks</h3>

                    <DataTable value={bundleStatus.tasks}
                        responsiveLayout="scroll"
                        emptyMessage="No selected artefacts."
                        className="p-datatable-bundle-status"
                    >
                        <Column field="name" header="Name" body={nameBodyTemplate} columnKey='name' />

                        <Column field="status" header="Status" body={statusBodyTemplate} />

                        <Column field="customStartTime" header="Start time" body={startTimeBodyTemplate} />

                        <Column field="customCompletionTime" header="Completion time" body={completionTimeBodyTemplate} />

                        <Column field="dependencies" header="Dependencies" body={dependenciesBodyTemplate} columnKey='dependencies' />
                    </DataTable>
                </Fieldset>
            </div>
        </div>
    );
};

export default BundleStatus;