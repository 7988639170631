//React imports
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

//---

//CSS imports
import './Connections.css'
//---

//PrimeReact imports

//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import { GlobalAecProjectStateContext } from '../../components/GlobalAecProjectStateProvider';
import ConnectionsInventory from '../../components/connection/ConnectionsInventory';
import ConnectionDetails from '../../components/connection/ConnectionDetails';
//---

//Data requests imports
import {
    listSingerConnections,
    readSingerConnection,
    deleteSingerConnection
} from '../../data/SingerConnectionData';
//---

const Connections = ({ projectName }) => {
    const { aecProject } = useContext(GlobalAecProjectStateContext);

    const history = useHistory();

    const { showNotification } = useNotification();

    const cancelTokenSource = axios.CancelToken.source();

    const [connections, setConnections] = useState([])
    const [selectedConnection, setSelectedConnection] = useState({})

    useEffect(() => {
        if (aecProject.name) {
            listSingerConnectionsCtlr();
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aecProject]);

    useEffect(() => {
        const unlisten = history.listen(({ search }) => {
            let _urlSearchParams = new URLSearchParams(search);
            let connectionName = _urlSearchParams.get("selected-connection")
            if (connectionName) {
                if (aecProject.name) {
                    readSingerConnectionCtlr(connectionName)
                }
            }
        });
        
        return () => {
            unlisten();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, aecProject]);

    const listSingerConnectionsCtlr = () => {
        listSingerConnections(cancelTokenSource, projectName).then(
            data => {
                if (data.singerConnections) {
                    setConnections(data.singerConnections);
                    if (data.singerConnections.length > 0) {
                        let _urlSearchParams = new URLSearchParams(history.location.search);
                        let connectionName = _urlSearchParams.get("selected-connection")
                        if (connectionName) {
                            readSingerConnectionCtlr(connectionName)
                        } else {
                            setSelectedConnection(data.singerConnections[0])
                        }
                    }
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const readSingerConnectionCtlr = (singerConnectionName) => {
        readSingerConnection(cancelTokenSource, projectName, singerConnectionName).then(
            data => {
                if (data.singerConnection) {
                    setSelectedConnection(data.singerConnection);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const deleteSingerConnectionCtlr = (singerConnectionName) => {
        deleteSingerConnection(cancelTokenSource, projectName, singerConnectionName).then(
            () => {
                unselectConnection()
                listSingerConnectionsCtlr()
                showNotification('success', 'Success', 'Connection successfully deleted', 6000)
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const onSelectConnection = (connection) => {
        history.push({
            search: '?selected-connection=' + connection.name
        })
    }

    const unselectConnection = () => {
        history.replace({
            search: ''
        })
    }

    return (
        <div className='p-d-flex'>
            <ConnectionsInventory
                connections={connections}
                selectedConnection={selectedConnection}
                onSelectConnection={onSelectConnection}
                projectName={projectName}
            />
            {
                selectedConnection.name ?
                    <ConnectionDetails
                        selectedConnection={selectedConnection}
                        projectName={projectName}
                        deleteSingerConnection={deleteSingerConnectionCtlr}
                    /> :
                    <div />
            }
        </div>
    );
};

export default Connections;
