//React imports
import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
//---

//CSS imports
import './ConnectionDetails.css'
//---

//PrimeReact imports
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { Divider } from 'primereact/divider';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
//---

//Vendors imports
//---

//Components imports
//---

//Utils imports
import { formatFullDate } from '../../utils/Date';
import { getFilename } from '../../utils/Regex';
//---

const ConnectionDetails = ({ selectedConnection, projectName, deleteSingerConnection }) => {
    const history = useHistory();

    const [showPasswords, setShowPasswords] = useState([])

    const onEdit = () => {
        history.push({
            pathname: '/' + projectName + '/connection/update',
            search: 'selected-connection=' + selectedConnection.name
        })
    }

    const onDelete = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Do you want to delete this connection ?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => deleteSingerConnection(selectedConnection.name),
        });
    }

    const renderEnvironmentVar = (env, index) => {
        if (env[1].toString().startsWith('data:application/json')) {
            return (
                <p key={env[0]}><span className="p-text-bold">{env[0]} : </span>{getFilename(env[1])}</p>
            )
        } else if (env[0].toString() === 'PASSWORD' || env[0].toString() === 'AWS_ACCESS_KEY_ID' || env[0].toString() === 'AWS_SECRET_ACCESS_KEY' || env[0].toString() === 'AWS_SESSION_TOKEN') {
            return (
                <p key={env[0]}>
                    <span className="p-text-bold">
                        {env[0]} :&nbsp;
                    </span>
                    <span className="p-d-inline-flex p-ai-center">
                        <span className="p-mr-2">{showPasswords[index] ? env[1].toString() : "xxxxxxxxxxxx"}</span>
                        <Button
                            icon="pi pi-eye"
                            className="p-button-rounded p-button-secondary p-button-text show-hide-button"
                            onClick={() => {
                                let _showPasswords = []
                                if (showPasswords[index]) {
                                    _showPasswords[index] = false
                                } else {
                                    _showPasswords[index] = true
                                }
                                setShowPasswords(_showPasswords)
                            }}
                        />
                    </span>
                </p>
            )
        }
        else {
            return (
                <p key={env[0]}><span className="p-text-bold">{env[0]} : </span>{env[1].toString()}</p>
            )
        }
    }

    return (
        <div className='connection-details'>
            <ConfirmPopup />
            <Fieldset className='p-ml-2 p-mr-0 container' legend={selectedConnection.name}>
                <h3 className='title-color'>Details</h3>
                <p><span className="p-text-bold">Name : </span>{selectedConnection.name}</p>
                <p><span className="p-text-bold">Description : </span>{selectedConnection.description}</p>
                <p><span className="p-text-bold">Connection type : </span>{selectedConnection['connection-type']}</p>
                <p><span className="p-text-bold">Last modified : </span>{formatFullDate(selectedConnection['last-modified-time'])}</p>
                <p><span className="p-text-bold">Created : </span>{formatFullDate(selectedConnection['creation-time'])}</p>

                <Divider />

                <h3 className='title-color'>Environment :</h3>
                {Object.entries(selectedConnection.configuration).map((env, index) => renderEnvironmentVar(env, index))}

                <Divider />

                <h3 className='title-color'>Flow dependencies</h3>
                <Link className='resource-link' to={{
                    pathname: `/${projectName}/flows`,
                    search: `?search-connection=${selectedConnection.name}`
                }}>
                    <p>
                        Show which flows use the connection
                    </p>
                </Link>


                <Divider />

                <Button
                    className='p-mr-2'
                    label='Edit' icon="pi pi-pencil" onClick={onEdit}
                />
                <Button
                    className="p-button-danger p-button-outlined"
                    label='Delete' icon="pi pi-times" onClick={onDelete}
                />
            </Fieldset>
        </div>
    );
};

export default ConnectionDetails;
