//React imports
import React, { useState, useEffect, useContext } from 'react';
//---

//CSS imports
import './Dashboard.css'
//---

//PrimeReact imports
import { Skeleton } from 'primereact/skeleton';
//---

//Components import
import { GlobalAecProjectStateContext } from '../../components/GlobalAecProjectStateProvider';
import { useNotification } from '../../components/NotificationProvider';
//--

//Vendors imports
import axios from 'axios';
//---

//Data requests imports
import { getHomePage } from '../../data/DashboardData';

//---

const Dashboard = ({ projectName }) => {
    const { aecProject } = useContext(GlobalAecProjectStateContext);

    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    const [htmlContent, setHtmlContent] = useState('');

    const [htmlContentLoading, setHtmlContentLoading] = useState(false);

    useEffect(() => {
        if (aecProject.name) {
            getHomePageCtlr();
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aecProject]);

    const getHomePageCtlr = () => {
        setHtmlContentLoading(true);
        getHomePage(cancelTokenSource, projectName).then(
            data => {
                if (data) {
                    setHtmlContent(data);
                    setHtmlContentLoading(false);
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000)
                setHtmlContentLoading(false);
            }
        );
    }

    return (
        <div>
            {
                htmlContentLoading || htmlContent === '' ?
                    <div>
                        <div className="custom-skeleton p-p-4">
                            <div className="p-d-flex p-mb-3">
                                <Skeleton shape="circle" size="4rem" className="p-mr-2"></Skeleton>
                                <div>
                                    <Skeleton width="10rem" className="p-mb-2"></Skeleton>
                                    <Skeleton width="5rem" className="p-mb-2"></Skeleton>
                                    <Skeleton height=".5rem"></Skeleton>
                                </div>
                            </div>
                            <Skeleton width="100%" height="150px"></Skeleton>
                            <div className="p-d-flex p-jc-between p-mt-3">
                                <Skeleton width="4rem" height="2rem"></Skeleton>
                                <Skeleton width="4rem" height="2rem"></Skeleton>
                            </div>
                        </div>
                        <div className="p-field p-col-12 p-md-6 p-p-4">
                            <Skeleton className="p-mb-2"></Skeleton>
                            <Skeleton width="10rem" className="p-mb-2"></Skeleton>
                            <Skeleton width="5rem" className="p-mb-2"></Skeleton>
                            <Skeleton height="2rem" className="p-mb-2"></Skeleton>
                            <Skeleton width="10rem" height="4rem"></Skeleton>
                        </div>
                        <div className="custom-skeleton p-p-4">
                            <div className="p-d-flex p-mb-3">
                                <Skeleton shape="circle" size="4rem" className="p-mr-2"></Skeleton>
                                <div>
                                    <Skeleton width="10rem" className="p-mb-2"></Skeleton>
                                    <Skeleton width="5rem" className="p-mb-2"></Skeleton>
                                    <Skeleton height=".5rem"></Skeleton>
                                </div>
                            </div>
                            <Skeleton width="100%" height="150px"></Skeleton>
                            <div className="p-d-flex p-jc-between p-mt-3">
                                <Skeleton width="4rem" height="2rem"></Skeleton>
                                <Skeleton width="4rem" height="2rem"></Skeleton>
                            </div>
                        </div>
                    </div> :
                    <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
            }
        </div>
    );
};

export default Dashboard;
