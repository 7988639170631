
export const getFilename = (data) => {
    let regex = /name=([\w-.]*)/g;
    let result = regex.exec(data)
    if (result && result.length > 1) {
        return result[1]
    }

    return 'not specified'
}

export const isURL = (str) => {
    let tab = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    return tab.test(str);
};