//React imports
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

//---

//CSS imports
import './Flow.css'
//---

//PrimeReact imports
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import { GlobalAecProjectStateContext } from '../../components/GlobalAecProjectStateProvider';
import FlowJobsTable from '../../components/flow/FlowJobsTable';
import FlowDetails from '../../components/flow/FlowDetails';
//---

//Data requests imports
import {
    readSingerFlow,
    deleteSingerFlow
} from '../../data/SingerFlowData';
import {
    listTaskStatuses,
    processTaskStatusesList,
    execTask,
    scheduleTask,
    unscheduleTask
} from '../../data/TaskData';
import {
    getArtefact
} from '../../data/ArtefactData';
import {
    getLastSelectedTaskEngine,
    setLastSelectedTaskEngine
} from '../../data/AppLocalData';
import { defaultFlow } from '../../data/DefaultStates';
//---

const Flow = ({ projectName, flowName }) => {
    const { aecProject } = useContext(GlobalAecProjectStateContext);

    const history = useHistory();

    const { showNotification } = useNotification();

    const cancelTokenSource = axios.CancelToken.source();

    const [flow, setFlow] = useState(defaultFlow)

    const [cronTask, setCronTask] = useState(null);

    const [flowJobs, setFlowJobs] = useState([])

    const [runLoading, setRunLoading] = useState(false);

    const [refreshIsLoading, setRefreshIsLoading] = useState(false);

    const [scheduleTaskLoading, setScheduleTaskLoading] = useState(false);

    const [selectedEngine, setSelectedEngine] = useState(() => {
        return getLastSelectedTaskEngine()
    })

    useEffect(() => {
        if (aecProject.name) {
            readSingerFlowCtlr(flowName);
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aecProject]);

    useEffect(() => {
        if (aecProject.name) {
            listTaskStatusesCtlr(projectName, selectedEngine, flowName)
            getCronTaskCtlr(projectName, selectedEngine, flowName)
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEngine, aecProject]);

    const readSingerFlowCtlr = (singerFlowName) => {
        readSingerFlow(cancelTokenSource, projectName, singerFlowName).then(
            data => {
                if (data.singerFlow) {
                    setFlow(data.singerFlow);
                    listTaskStatusesCtlr(projectName, selectedEngine, singerFlowName)
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const deleteSingerFlowCtlr = (singerFlowName) => {
        if (cronTask) {
            showNotification('info', 'Info', "to remove the flow, you must first remove the triggers", 6000)
            return
        }

        deleteSingerFlow(cancelTokenSource, projectName, singerFlowName).then(
            () => {
                showNotification('success', 'Success', 'flow successfully deleted', 6000)
                history.replace('/' + projectName + '/flows')
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const runSingerFlowWrapper = (singerFlowName, engine) => {
        runSingerFlowCtlr(singerFlowName, engine)
    }

    const runSingerFlowCtlr = (singerFlowName, engine) => {
        setRunLoading(true);

        getArtefact(cancelTokenSource, projectName, singerFlowName).then(
            data => {
                if (data.artefact) {
                    execTaskCtlr(data.artefact, engine)
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000)
                setRunLoading(false);
            }
        );
    }

    const execTaskCtlr = (artefact, engine) => {
        let taskManifest = {
            'name': artefact.name,
            'description': artefact.description,
            //'kind': 'job',
            'artefact-origin': projectName + '/' + artefact.name,
            'artefact-version': artefact.version,
            'labels': [],
            'engine-setup': {
                'engine': engine
            },
            'env': artefact.env
        }

        let _taskManifest = ''

        try {
            _taskManifest = JSON.stringify(taskManifest);
        } catch (_) {
            showNotification('error', 'Error', 'invalid manifest file', 6000)
            return;
        }

        execTask(cancelTokenSource, projectName, _taskManifest).then(
            data => {
                if (data.taskManifest) {
                    showNotification('success', 'Success', 'the flow has been successfully launched', 6000);
                    listTaskStatusesCtlr(projectName, selectedEngine, flowName);
                }
                setRunLoading(false);
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
                setRunLoading(false);
            }
        );
    }

    const applyScheduleCtlr = (singerFlowName, engine, cronExpression) => {
        setScheduleTaskLoading(true);

        if (cronExpression === '' && cronTask) {
            unscheduleTaskCtlr(cronTask['redis-id'])
            return
        }

        getArtefact(cancelTokenSource, projectName, singerFlowName).then(
            data => {
                if (data.artefact) {
                    scheduleTaskCtlr(data.artefact, engine, cronExpression)
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000)
                setScheduleTaskLoading(false);
            }
        );
    }

    const scheduleTaskCtlr = (artefact, engine, cronExpression) => {
        let taskManifest = {
            'name': artefact.name,
            'description': artefact.description,
            //'kind': 'job',
            'schedule': cronExpression,
            'artefact-origin': projectName + '/' + artefact.name,
            'artefact-version': artefact.version,
            'labels': [],
            'engine-setup': {
                'engine': engine
            },
            'env': artefact.env
        }

        let _taskManifest = ''

        try {
            _taskManifest = JSON.stringify(taskManifest);
        } catch (_) {
            showNotification('error', 'Error', 'invalid files', 6000)
            return;
        }

        scheduleTask(cancelTokenSource, projectName, _taskManifest).then(
            data => {
                if (data.taskManifest) {
                    showNotification('success', 'Success', 'the flow has been successfully scheduled', 6000);
                    listTaskStatusesCtlr(projectName, engine, flowName);
                    getCronTaskCtlr(projectName, engine, flowName);
                }
                setScheduleTaskLoading(false);
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
                setScheduleTaskLoading(false);
            }
        );

    }

    const unscheduleTaskCtlr = (taskRedisID) => {
        unscheduleTask(cancelTokenSource, taskRedisID).then(
            () => {
                showNotification('success', 'Success', 'the flow has been successfully unscheduled', 6000);
                listTaskStatusesCtlr(projectName, selectedEngine, flowName);
                getCronTaskCtlr(projectName, selectedEngine, flowName);
                setScheduleTaskLoading(false);
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
                setScheduleTaskLoading(false);
            }
        );
    }

    const listTaskStatusesCtlr = (projectName, engine, singerFlowName) => {
        setRefreshIsLoading(true);
        listTaskStatuses(cancelTokenSource, projectName, engine, 'task', singerFlowName).then(
            data => {
                if (data.taskManifests) {
                    let _taskManifests = []

                    _taskManifests = processTaskStatusesList(data.taskManifests)

                    setFlowJobs(_taskManifests)
                    setRefreshIsLoading(false);
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
                setRefreshIsLoading(false);
            }
        );
    }

    const getCronTaskCtlr = (projectName, engine, singerFlowName) => {
        setScheduleTaskLoading(true);
        listTaskStatuses(cancelTokenSource, projectName, engine, 'cron', singerFlowName).then(
            data => {
                if (data.taskManifests) {
                    if (data.taskManifests.length >= 1) {
                        setCronTask(data.taskManifests[0])
                    } else {
                        setCronTask(null)
                    }

                    setScheduleTaskLoading(false);
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000)
                setScheduleTaskLoading(false);
            }
        );
    }

    return (
        <div className='flow'>
            <FlowDetails
                flow={flow}
                projectName={projectName}
                deleteSingerFlow={deleteSingerFlowCtlr}
            />

            <FlowJobsTable
                flow={flow}
                projectName={projectName}
                runSingerFlowWrapper={runSingerFlowWrapper}
                runLoading={runLoading}
                cronTask={cronTask}
                applySchedule={applyScheduleCtlr}
                scheduleTaskLoading={scheduleTaskLoading}
                flowJobs={flowJobs}
                selectedEngine={selectedEngine}
                availableEngines={aecProject.eventSchedulerTaskEngines}
                setSelectedEngine={(engine) => {
                    setSelectedEngine(engine);
                    setLastSelectedTaskEngine(engine);
                }}
                onClickRefresh={() => {
                    listTaskStatusesCtlr(projectName, selectedEngine, flowName)
                }}
                refreshIsLoading={refreshIsLoading}
            />
        </div>
    );
};

export default Flow;
