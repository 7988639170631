//React imports
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
//---

//CSS imports
import './PutService.css'
//---

//PrimeReact imports
import { Avatar } from 'primereact/avatar';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import ServiceForm from '../../components/service/ServiceForm';
//---

//Data requests imports
import {
    createService,
    updateService,
    getService,
} from '../../data/ServiceData';
import { defaultService } from '../../data/DefaultStates';
//---

const PutService = ({ projectName, serviceRedisID, mode }) => {
    const history = useHistory();

    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    const [existingService, setExistingService] = useState(null);

    useEffect(() => {
        if (mode === 'update') {
            getServiceCtlr(serviceRedisID)
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createServiceCtlr = (service) => {
        createService(cancelTokenSource, projectName, service).then(
            data => {
                showNotification('success', 'Success', 'service successfully created', 6000)
                history.push({
                    pathname: '/' + projectName + "/services/" + (data.service ? data.service['redis-id'] : 'undefined')
                })
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const updateServiceCtlr = (service) => {
        updateService(cancelTokenSource, service).then(
            data => {
                showNotification('success', 'Success', 'service successfully updated', 6000)
                history.push({
                    pathname: '/' + projectName + "/services/" + (data.service ? data.service['redis-id'] : 'undefined')
                })
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }


    const getServiceCtlr = (serviceRedisID) => {
        getService(cancelTokenSource, serviceRedisID).then(
            data => {
                if (data.service) {
                    setExistingService(data.service);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const onFormSubmit = ({formData}) => {
        let service = {
            'name': formData['name'],
            'description': formData['description'],
            'image': formData['image'],
            'git-url': formData['git-url'],
            'git-branch': formData['git-branch'],
            'code-path': formData['code-path'],
            'port': formData['port'],
            'env': formData['env'],
            'use-grpx': formData['use-grpx'],
        };

        if (mode === 'update') {
            service['id'] = existingService['id']
            service['redis-id'] = existingService['redis-id']
        }

        if (mode === 'create') {
            createServiceCtlr(service);
        } else {
            updateServiceCtlr(service)
        }
    }

    return (
        <div className='put-service'>
            <div className='p-d-flex p-flex-column p-jc-center p-ai-center'>
                <Avatar
                    icon='pi pi-box'
                    className='xxl-avatar p-m-3'
                />
                <div className='title'>Fill out the form to create your service</div>
            </div>
            <div className='p-d-flex p-jc-center p-pb-3 container'>
                <ServiceForm
                    onFormSubmit={onFormSubmit}
                    formData={existingService ? existingService : defaultService}
                    mode={mode}
                />
            </div>
        </div>
    );
};

export default PutService;