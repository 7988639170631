//Vendors imports
import axios from 'axios';
//---

//Utils imports
import { log } from '../utils/Log';
//---

const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;
const AEC_API_URL = process.env.REACT_APP_AEC_API_URL;

export const createUser = (cancelTokenSource, user) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AUTH_API_URL + '/v1/admin/user/create',
            data: user,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const listUsers = (cancelTokenSource) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: AUTH_API_URL + '/v1/admin/user/list',
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};


export const updateUser = (cancelTokenSource, user) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'PUT',
            url: AUTH_API_URL + '/v1/admin/user/update',
            data: user,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const deleteUser = (cancelTokenSource, user) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'DELETE',
            url: AUTH_API_URL + '/v1/admin/user/delete',
            data: user,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const createUserAccessToken = (cancelTokenSource, accessToken) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AUTH_API_URL + '/v1/admin/user/access-token/create',
            data: accessToken,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const revokeUserAccessToken = (cancelTokenSource, accessToken) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'DELETE',
            url: AUTH_API_URL + '/v1/admin/user/access-token/revoke',
            data: accessToken,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const customPluginsFilterFunction = (value, filter) => {
    if (!filter || filter.length === 0) {
        return true;
    }

    if (value && value.length > 0) {
        if (filter && filter.length > 0) {
            for (let i = 0; i < value.length; i++) {
                for (let j = 0; j < filter.length; j++) {
                    if (value[i].name && value[i].name === filter[j]) {
                        return true;
                    }
                }
            }
        }
    } else {
        if (filter) {
            return false;
        }
    }

    return false;
}

export const customAccessFilterFunction = (value, filter) => {
    if (!filter || filter.length === 0) {
        return true;
    }

    if (filter && filter.length > 0) {
        for (let i = 0; i < filter.length; i++) {
            if (filter.length === 1 && filter[i] === 'all') {
                //Check if full access
                if (value) {
                    let keys = Object.keys(value)
                    for (let key of keys) {
                        if (value[key].active === false) {
                            return false
                        }
                    }
                    return true;
                } else {
                    return false;
                }
            }

            if (value && value[filter[i]] && value[filter[i]].active) {
                return true
            }
        }
    }

    return false;
}

export const customACLFilterFunction = (value, filter) => {
    if (!filter || filter.length === 0) {
        return true;
    }

    if (filter && filter.length > 0) {
        for (let i = 0; i < filter.length; i++) {
            if (filter[i] === 'data-explorer:all') {
                if (value && value['data-explorer'] && value['data-explorer'].active &&
                    (!value['data-explorer'].acl || Object.keys(value['data-explorer'].acl).length === 0)) {
                    return true;
                }
            }

            if (filter[i] === 'data-explorer:restricted') {
                if (value && value['data-explorer'] && value['data-explorer'].active &&
                    (value['data-explorer'].acl && Object.keys(value['data-explorer'].acl).length > 0)) {
                    return true;
                }
            }

            if (filter[i] === 'object-storage:all') {
                if (value && value['object-storage'] && value['object-storage'].active &&
                    (!value['object-storage'].acl || Object.keys(value['object-storage'].acl).length === 0)) {
                    return true;
                }
            }

            if (filter[i] === 'object-storage:restricted') {
                if (value && value['object-storage'] && value['object-storage'].active &&
                    (value['object-storage'].acl && Object.keys(value['object-storage'].acl).length > 0)) {
                    return true;
                }
            }
        }
    }

    return false;
}

export const execJobGitCommandOnAecProject = (cancelTokenSource, projectName, cmd) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        if (cmd !== '') {
            formData.append('cmd', cmd);
        }

        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/admin/job/exec/git-command/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const getAdminJobState = (cancelTokenSource, jobID) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: AEC_API_URL + '/api/v1/admin/job/state/' + jobID,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};