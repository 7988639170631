//React imports
import React, { useState, useEffect, useContext } from 'react';
//---

//CSS imports
import './Task.css'
//---

//PrimeReact imports
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import { GlobalAecProjectStateContext } from '../../components/GlobalAecProjectStateProvider';
import TaskDetails from '../../components/task/TaskDetails';
//---

//Data requests imports
import {
    getTaskStatus,
} from '../../data/TaskData';
import { defaultTask } from '../../data/DefaultStates';
//---

const Task = ({ projectName, taskRedisID }) => {
    const { aecProject } = useContext(GlobalAecProjectStateContext);

    const { showNotification } = useNotification();

    const cancelTokenSource = axios.CancelToken.source();

    const [task, setTask] = useState(defaultTask)

    const [refreshIsLoading, setRefreshIsLoading] = useState(false);

    useEffect(() => {
        if (aecProject.name) {
            getTaskStatusCtlr(taskRedisID);
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aecProject]);

    const getTaskStatusCtlr = (taskRedisID) => {
        setRefreshIsLoading(true);
        getTaskStatus(cancelTokenSource, taskRedisID).then(
            data => {
                if (data.taskManifest) {
                    setTask(data.taskManifest);
                    setRefreshIsLoading(false);
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
                setRefreshIsLoading(false);
            }
        );
    }

    return (
        <div className='task'>
            <TaskDetails
                task={task}
                projectName={projectName}
                onClickRefresh={() => {getTaskStatusCtlr(taskRedisID)}}
                refreshIsLoading={refreshIsLoading}
            />
        </div>
    );
};

export default Task;