//React imports
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
//---

//CSS imports
import './ObjectStorage.css'
//---

//PrimeReact imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { classNames } from 'primereact/utils';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import { skeletonPreview } from '../../components/skeletons/SkeletonPreview';
//---

//Utils imports
import { unparseDashInPath } from '../../utils/Parser';
import { parseFileSize } from '../../utils/Files';
import { log } from '../../utils/Log';
//---

//Data requests imports
import {
    listObjectStorageBuckets,
    listObjectStorageObjects,
    assembleObjectList,
    previewObject,
    deleteBucket,
    deleteObject,
    createBucket,
    createDirObject,
    getObjectDownloadLink,
    getObjectUploadLink,
    uploadObjectToUploadLink
} from '../../data/ObjectStorageData';
//---

const defaultFileSize = '1000'
const fileSizes = [
    { size: '100', label: '100' },
    { size: '500', label: '500' },
    { size: '1000', label: '1000 (default)' },
    { size: '5000', label: '5000' },
    { size: '10000', label: '10000' }
];

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const ObjectStorage = ({ projectName }) => {
    const history = useHistory();
    const query = useQuery();

    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    const dt = useRef(null);

    const bucketInputRef = useRef(null);
    const objectDirInputRef = useRef(null);
    const uploadInputRef = useRef(null);

    const [globalFilter, setGlobalFilter] = useState('');
    const globalFilterInputRef = useRef(null);

    const [buckets, setBuckets] = useState([]);

    const [objectList, setObjectList] = useState({
        prefix: '',
        delimiter: '',
        objects: []
    });

    const [dtLoading, setDtLoading] = useState(false);

    const [previewIsOpen, setPreviewIsOpen] = useState(false);
    const [previewLoading, setPreviewLoading] = useState(false);
    const [previewFileName, setPreviewFileName] = useState({
        Key: '',
        ObjectName: ''
    });
    const [previewSelectedFileSize, setPreviewSelectedFileSize] = useState(null);
    const [previewFileContent, setPreviewFileContent] = useState('');

    const [uploadObjectLoading, setUploadObjectLoading] = useState(false);

    useEffect(() => {
        listObjectStorageBucketsCtlr();

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        listObjectStorageObjectsCtlr();

        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.search]);

    const listObjectStorageBucketsCtlr = () => {
        listObjectStorageBuckets(cancelTokenSource, projectName).then(
            data => {
                if (data.buckets) {
                    setBuckets(data.buckets);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const listObjectStorageObjectsCtlr = () => {
        let bucketName = query.get("bucket-name")
        if (bucketName) {
            let prefix = query.get("prefix")
            if (prefix) {
                prefix = unparseDashInPath(prefix)
            } else {
                prefix = ''
            }

            setDtLoading(true);
            listObjectStorageObjects(cancelTokenSource, projectName, bucketName, prefix).then(
                data => {
                    if (data.objectList) {
                        let _objectList = {
                            prefix: data.objectList.prefix,
                            delimiter: data.objectList.delimiter,
                            objects: []
                        }
                        _objectList.objects = assembleObjectList(data.objectList);
                        setObjectList(_objectList);
                        setDtLoading(false);
                        if (_objectList.objects.length >= 10000) {
                            showNotification('info', 'Partial result displayed.',
                                `Partial list displayed. The number of objects is too large to be displayed completely, 
                                only the list of 10000 objects in alphabetical order is collected`, 12000)
                        }
                    }
                },
                errorMessage => {
                    setDtLoading(false);
                    showNotification('error', 'Error', errorMessage, 6000);
                }
            );
        }
    }

    const previewObjectCtlr = (fileName, fileSize) => {
        let bucketName = query.get("bucket-name")
        if (bucketName) {
            setPreviewLoading(true);
            previewObject(cancelTokenSource, projectName, bucketName, fileName, fileSize).then(
                data => {
                    if (data.preview) {
                        if (fileName.endsWith('.json')) {
                            let dataPreview = data.preview;
                            try {
                                dataPreview = JSON.stringify(JSON.parse(data.preview), null, 2)
                            } catch {
                                log('debug', 'cannot prettify json : ' + fileName);
                            }

                            setPreviewFileContent(dataPreview);
                        } else {
                            setPreviewFileContent(data.preview);
                        }

                        setPreviewLoading(false);
                    }
                },
                errorMessage => {
                    setPreviewLoading(false);
                    showNotification('error', 'Error', errorMessage, 6000);
                }
            );
        }
    }

    const createBucketCtlr = (bucketName) => {
        if (bucketName) {
            createBucket(cancelTokenSource, projectName, bucketName).then(
                () => {
                    showNotification('success', 'Success', 'bucket successfully created', 6000);
                    listObjectStorageBucketsCtlr();
                    bucketInputRef.current.value = '';
                },
                errorMessage => {
                    showNotification('error', 'Error', errorMessage, 6000);
                }
            );
        }
    }

    const createDirObjectCtlr = (dirName) => {
        if (dirName) {
            let bucketName = query.get("bucket-name")
            if (bucketName) {
                let prefix = query.get("prefix")
                if (prefix) {
                    prefix = unparseDashInPath(prefix)
                } else {
                    prefix = ''
                }

                let dirPath = prefix + '/' + dirName + '/'

                createDirObject(cancelTokenSource, projectName, bucketName, dirPath).then(
                    () => {
                        showNotification('success', 'Success', 'directory successfully created', 6000);
                        objectDirInputRef.current.value = '';
                        listObjectStorageObjectsCtlr();
                    },
                    errorMessage => {
                        showNotification('error', 'Error', errorMessage, 6000);
                    }
                );
            } else {
                showNotification('error', 'Error', 'select a bucket first', 6000);
            }
        }
    }

    const getObjectDownloadLinkCtlr = (fileName) => {
        let bucketName = query.get("bucket-name")
        if (bucketName) {
            getObjectDownloadLink(cancelTokenSource, projectName, bucketName, fileName).then(
                data => {
                    if (data.downloadLink) {
                        window.open(data.downloadLink, '_blank').focus();
                    }
                },
                errorMessage => {
                    showNotification('error', 'Error', errorMessage, 6000);
                }
            );
        }
    }

    const getObjectUploadLinkCtlr = (file) => {
        let bucketName = query.get("bucket-name")
        if (bucketName) {
            setUploadObjectLoading(true);

            let prefix = query.get("prefix")
            if (prefix) {
                prefix = unparseDashInPath(prefix)
            } else {
                prefix = ''
            }

            let fileName = prefix + '/' + file.files[0].name

            getObjectUploadLink(cancelTokenSource, projectName, bucketName, fileName).then(
                data => {
                    if (data.uploadLink) {
                        uploadObjectToUploadLink(data.uploadLink, file.files[0], fileName).then(
                            data => {
                                if (data) {
                                    uploadInputRef.current.clear();
                                    listObjectStorageObjectsCtlr();
                                    setUploadObjectLoading(false);
                                }
                            },
                            errorMessage => {
                                showNotification('error', 'Error', errorMessage, 6000);
                                uploadInputRef.current.clear();
                                setUploadObjectLoading(false);
                            }
                        );
                    }
                },
                errorMessage => {
                    showNotification('error', 'Error', errorMessage, 6000);
                    uploadInputRef.current.clear();
                    setUploadObjectLoading(false);
                }
            );
        } else {
            showNotification('error', 'Error', 'select a bucket first', 6000);
            uploadInputRef.current.clear();
            setUploadObjectLoading(false);
        }
    }

    const deleteBucketCtlr = () => {
        let bucketName = query.get("bucket-name")
        if (bucketName) {
            deleteBucket(cancelTokenSource, projectName, bucketName).then(
                () => {
                    showNotification('success', 'Success', 'bucket successfully deleted', 6000)
                    setQueryParam('bucket-name', '')
                    listObjectStorageBucketsCtlr();
                },
                errorMessage => {
                    showNotification('error', 'Error', errorMessage, 6000);
                }
            );
        }
    }

    const deleteObjectCtlr = (fileName) => {
        let bucketName = query.get("bucket-name")
        if (bucketName) {
            deleteObject(cancelTokenSource, projectName, bucketName, fileName).then(
                () => {
                    showNotification('success', 'Success', 'object successfully deleted', 6000)
                    listObjectStorageObjectsCtlr();
                },
                errorMessage => {
                    showNotification('error', 'Error', errorMessage, 6000);
                }
            );
        }
    }

    const onDeleteBucket = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Do you want to delete this bucket ?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => deleteBucketCtlr(),
        });
    }

    const onDeleteObject = (event, object) => {
        if (object.objectCount > 0) {
            showNotification('error', 'Error', "the directory is not empty", 6000);
            return
        }

        confirmPopup({
            target: event.currentTarget,
            message: 'Do you want to delete this object ?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => deleteObjectCtlr(object.Key),
        });
    }

    const setQueryParam = (param, value) => {
        const params = new URLSearchParams(query)

        if (param === 'bucket-name') {
            params.delete('prefix')
        }

        params.set(param, value)

        history.push({
            search: params.toString()
        })
    }

    const backPrefix = () => {
        const params = new URLSearchParams(query)
        let prefix = query.get("prefix")
        if (prefix) {
            let parts = prefix.split('/');
            if (parts.length > 2) {
                let prefixParts = parts.slice(0, parts.length - 2)
                let newPrefix = prefixParts.join('/') + '/'
                params.set('prefix', newPrefix)
            } else {
                params.delete('prefix')
            }
            history.push({
                search: params.toString()
            })
        }
    }

    const getCurrentBucket = () => {
        return query.get("bucket-name")
    }

    const onGlobalFilterSearch = (globalFilterValue) => {
        setGlobalFilter(globalFilterValue);
        dt.current.filter(globalFilterValue, 'global', 'contains');
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onGlobalFilterSearch(globalFilterInputRef.current.value);
        }
    }

    const reset = () => {
        setGlobalFilter('');
        globalFilterInputRef.current.value = '';
        dt.current.reset();
    }

    const typeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div>
                    {
                        rowData.Type === 'dir' ?
                            <Avatar icon="pi pi-folder" className='type-icon' /> :
                            <Avatar icon="pi pi-file" className='type-icon' />
                    }
                </div>
            </React.Fragment>
        );
    }

    const objectNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='object-name'>{rowData.ObjectName}</div>
            </React.Fragment>
        );
    }

    const sizeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div>{rowData.Type === 'file' ? parseFileSize(rowData.Size) : '-'}</div>
            </React.Fragment>
        );
    }

    const lastModifiedBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div>{rowData.LastModified}</div>
            </React.Fragment>
        );
    }

    const storageClassBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div>{rowData.StorageClass}</div>
            </React.Fragment>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Avatar
                    icon='pi pi-times'
                    className='avatar-button ignore-row-click-event p-mr-2'
                    onClick={(event) => onDeleteObject(event, rowData)}
                />
                {
                    rowData.Type === 'file' ?
                        <Avatar
                            icon='pi pi-download'
                            className='avatar-button ignore-row-click-event'
                            onClick={() => getObjectDownloadLinkCtlr(rowData.Key)}
                        /> :
                        null
                }
            </React.Fragment>
        );
    }

    const header = (
        <div>
            <div className="table-header p-mb-2">
                <div className='p-d-inline-flex p-ai-center navigation'>
                    <Avatar icon='pi pi-angle-left' className='button-back' onClick={backPrefix} />
                    <div className='p-ml-2'>{objectList.prefix !== '' ? objectList.prefix : '/'}</div>
                </div>
                <div>
                    <span className="p-input-icon-left p-mr-2">
                        <i className="pi pi-search" />
                        <InputText type="search" ref={globalFilterInputRef} placeholder="Global Search" className="searchbox" onKeyDown={handleKeyDown} />
                        <Button type="button" icon="pi pi-search" className="p-button-outlined p-ml-2" onClick={() => onGlobalFilterSearch(globalFilterInputRef.current.value)} />
                    </span>
                    <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={reset} />
                </div>
            </div>
            <div className='p-d-flex p-ai-center p-mb-2'>
                <InputText
                    ref={objectDirInputRef}
                    type="text"
                    className="p-inputtext-sm block p-mr-2 bucket-input"
                    placeholder="New directory"
                />
                <Avatar
                    icon='pi pi-plus'
                    className='avatar-button'
                    onClick={() => createDirObjectCtlr(objectDirInputRef.current.value)}
                />
            </div>
            <div className='p-d-flex p-ai-center '>
                <FileUpload
                    ref={uploadInputRef}
                    className='p-mr-2'
                    mode="basic"
                    name="object-upload"
                    accept="*"
                    disabled={uploadObjectLoading}
                    chooseLabel='Upload a file'
                    customUpload={true}
                    auto
                    uploadHandler={(file) => getObjectUploadLinkCtlr(file)}
                />
                {
                    uploadObjectLoading ?
                        <ProgressSpinner
                            style={{ width: '2rem', height: '2rem', margin: 'unset' }}
                            strokeWidth='4' animationDuration='6s'
                        /> :
                        null
                }
            </div>
        </div>
    );

    return (
        <div className='object-storage'>
            <ConfirmPopup />
            <div className='bucket-menu'>
                <h3 className="p-text-normal p-mt-0 bucket-title">
                    Buckets
                </h3>
                {buckets.map((bucket, index) =>
                    <div key={`${bucket}-${index}`}
                        className={classNames('bucket-container p-d-inline-flex p-ai-center p-mb-2',
                            { 'selected-bucket': getCurrentBucket() === bucket })}
                        onClick={() => { setQueryParam('bucket-name', bucket) }}
                    >
                        <div
                            className='bucket p-mr-2'
                        >
                            {bucket}
                        </div>
                        {
                            getCurrentBucket() === bucket ?
                                <Avatar
                                    icon='pi pi-times'
                                    className='avatar-button'
                                    onClick={onDeleteBucket}
                                /> :
                                null
                        }
                    </div>
                )}
                <div
                    className='bucket-container p-d-inline-flex p-ai-center p-mb-2'
                >
                    <InputText
                        ref={bucketInputRef}
                        type="text"
                        className="p-inputtext-sm block p-mr-2 bucket-input"
                        placeholder="New bucket"
                    />
                    <Avatar
                        icon='pi pi-plus'
                        className='avatar-button'
                        onClick={() => createBucketCtlr(bucketInputRef.current.value)}
                    />
                </div>
            </div>
            <DataTable ref={dt} value={objectList.objects} paginator rows={8}
                header={header}
                className="object-table p-ml-2"
                selectionMode="single"
                sortField='Type'
                responsiveLayout="scroll"
                sortOrder={1}
                loading={dtLoading}
                onRowClick={(event) => {
                    if (!event.originalEvent.target.parentElement.className.includes('ignore-row-click-event')) {
                        if (event.data.Type === 'dir') {
                            setQueryParam('prefix', event.data.Key)
                        } else {
                            setPreviewFileName({
                                Key: event.data.Key,
                                ObjectName: event.data.ObjectName
                            })
                            let fileSize = defaultFileSize
                            if (previewSelectedFileSize) {
                                fileSize = previewSelectedFileSize
                            }
                            previewObjectCtlr(event.data.Key, fileSize);
                            setPreviewIsOpen(true)
                        }
                    }
                }}
                globalFilter={globalFilter} emptyMessage="No objects found.">

                <Column field="Type" header="Type" body={typeBodyTemplate} sortable />

                <Column field="ObjectName" header="Name" body={objectNameBodyTemplate} sortable />

                <Column field="Size" header="Size" body={sizeBodyTemplate} sortable />

                <Column field="LastModified" header="Last modified" body={lastModifiedBodyTemplate} sortable />

                <Column field="StorageClass" header="Storage classs" body={storageClassBodyTemplate} sortable />

                <Column className="ignore-row-click-event" body={actionBodyTemplate} exportable={false} />
            </DataTable>
            <Dialog
                className='preview-dialog'
                header={"Preview : " + previewFileName.ObjectName}
                visible={previewIsOpen}
                maximizable
                modal
                style={{ width: '80vw' }}
                onHide={() => {
                    setPreviewIsOpen(false);
                    setPreviewSelectedFileSize(null);
                }}
            >
                <div className='p-pt-2' style={{ height: '100%' }}>
                    <Dropdown
                        className='p-ml-4'
                        value={previewSelectedFileSize}
                        options={fileSizes}
                        onChange={(e) => {
                            setPreviewSelectedFileSize(e.value);
                            previewObjectCtlr(previewFileName.Key, e.value.size);
                        }}
                        optionLabel="label"
                        placeholder="Select the preview size"
                    />
                    <div className='preview-content'>
                        {
                            previewLoading ?
                                skeletonPreview :
                                <pre className='p-m-0'>{previewFileContent}</pre>
                        }
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default ObjectStorage;
