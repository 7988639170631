//React imports
import React from 'react';
//---

//CSS imports
import './ConnectorForm.css'
//---

//PrimeReact imports
import { Button } from 'primereact/button';
//---

//Vendors imports
import Form from '@rjsf/core';
//---

//Components imports
import { useNotification } from "../NotificationProvider";
import { ObjectFieldTemplate, CustomFieldTemplate, CustomWidgets } from '../CustomJsonForm';
//---

const ConnectorForm = ({ selectedConnection, selectedConnector, onCreateConnector, formData, mode = 'create' }) => {

    const { showNotification } = useNotification();

    const onFormSubmit = ({ formData }, e) => {
        let connectorData = {
            'name': selectedConnector.name,
            'singer-source': selectedConnector['singer-source'],
            'icon': selectedConnector.icon,
            'configuration': formData,
            'connection': {
                'name': selectedConnection.name,
                'description': selectedConnection.description,
                'connection-type': selectedConnection['connection-type'],
                'icon': selectedConnection.icon
            }
        };

        try {
            JSON.stringify(connectorData);
        } catch (_) {
            showNotification('error', 'Cannot perform', 'the form is invalid, please try again or contact an administrator.', 6000)
            return;
        }

        onCreateConnector(connectorData);
    }

    return (
        <Form
            className='p-fluid connector-form'
            schema={selectedConnector}
            uiSchema={selectedConnector.uiSchema}
            autoComplete='on'
            onSubmit={onFormSubmit}
            showErrorList={false}
            ObjectFieldTemplate={ObjectFieldTemplate}
            FieldTemplate={CustomFieldTemplate}
            widgets={CustomWidgets}
            formData={formData}
        >
            <div className="p-d-flex">
                <Button label={mode === 'create' ? 'Create' : 'Update'} type='submit' />
            </div>
        </Form>
    );
};

export default ConnectorForm;
