//React imports
import React, { useContext, useEffect } from 'react';
//---

//CSS imports
import './Test.css'
//---

//PrimeReact imports
import { Toolbar } from 'primereact/toolbar';
import { Fieldset } from 'primereact/fieldset';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import { GlobalAecProjectStateContext } from '../../components/GlobalAecProjectStateProvider';
import TestTree from '../../components/test/TestTree';
import DbtJobExec from '../../components/transform/DbtJobExec';
import DbtFileSessionsTable from '../../components/transform/DbtFileSessionsTable';
//---

//Data requests imports
import {
    listDbtTests,
    transformDbtTestList,
} from '../../data/DbtTestData';
//---

const Test = ({ projectName }) => {
    const { aecProject, setAecProjectTests } = useContext(GlobalAecProjectStateContext);

    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    useEffect(() => {
        listDbtTestsCtlr();

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listDbtTestsCtlr = () => {
        listDbtTests(cancelTokenSource, projectName).then(
            data => {
                if (data.dbtTests) {
                    setAecProjectTests(transformDbtTestList(data.dbtTests))
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const testHeader = () => {
        const leftContents = (
            <React.Fragment>
                <DbtJobExec
                    label='Run tests'
                    title='Run tests'
                    projectName={aecProject.name}
                    cmd='dbt'
                    defaultCommand='test'
                    defaultArgs=''
                    lockDbtCommand={true}
                />
            </React.Fragment>
        );

        return (
            <Toolbar
                left={<div style={{ fontWeight: '600' }}>Tests</div>}
                right={leftContents}
            />
        )
    }

    return (
        <div className='test'>
            <Fieldset>
                {testHeader()}
                <div className='test-container'>
                    <div className='trees-container p-pr-2'>
                        <TestTree
                            projectName={projectName}
                            tests={aecProject.dbtTests}
                            listDbtTests={listDbtTestsCtlr}
                            className='tree-container p-pt-1'
                        />
                    </div>
                    <div className='dbt-file-session-container'>
                        <DbtFileSessionsTable
                            projectName={projectName}
                            entityTypeFilter={'test'}
                        />
                    </div>
                </div>
            </Fieldset>
        </div>
    );
};

export default Test;

/*
<ReactMarkdown
    className='markdown-container'
>
    {aecProject.description}
</ReactMarkdown>
*/