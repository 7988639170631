//Vendors imports
import axios from 'axios';
//---

//Utils imports
import { log } from '../utils/Log';
import { formatFullDate } from '../utils/Date';
//---

const AEC_API_URL = process.env.REACT_APP_AEC_API_URL;

export const getBundle = (cancelTokenSource, bundleRedisID) => {
    let formData = new FormData();
    if (bundleRedisID !== '') {
        formData.append('bundleRedisID', bundleRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/bundle/get',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const createBundle = (cancelTokenSource, projectName, bundle) => {
    let formData = new FormData();
    if (bundle !== '') {
        formData.append('bundle', bundle);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/bundle/create/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const updateBundle = (cancelTokenSource, bundle) => {
    let formData = new FormData();
    if (bundle !== '') {
        formData.append('bundle', bundle);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'PUT',
            url: AEC_API_URL + '/api/v1/event-scheduler/bundle/update',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const deleteBundle = (cancelTokenSource, bundleRedisID) => {
    let formData = new FormData();
    if (bundleRedisID !== '') {
        formData.append('bundleRedisID', bundleRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'DELETE',
            url: AEC_API_URL + '/api/v1/event-scheduler/bundle/delete',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const listBundles = (cancelTokenSource, projectName) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: AEC_API_URL + '/api/v1/event-scheduler/bundle/list/' + projectName,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const runBundle = (cancelTokenSource, engine, bundleRedisID) => {
    let formData = new FormData();
    if (engine !== '') {
        formData.append('engine', engine);
    }
    if (bundleRedisID !== '') {
        formData.append('bundleRedisID', bundleRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/bundle/run',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const getBundleStatus = (cancelTokenSource, bundleStatusRedisID) => {
    let formData = new FormData();
    if (bundleStatusRedisID !== '') {
        formData.append('bundleStatusRedisID', bundleStatusRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/bundle/status/get',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const listBundleStatusesOfBundle = (cancelTokenSource, engine, bundleRedisID) => {
    let formData = new FormData();
    if (engine !== '') {
        formData.append('engine', engine);
    }
    if (bundleRedisID !== '') {
        formData.append('bundleRedisID', bundleRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/bundle/status/list',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const stopBundle = (cancelTokenSource, bundleStatusRedisID) => {
    let formData = new FormData();
    if (bundleStatusRedisID !== '') {
        formData.append('bundleStatusRedisID', bundleStatusRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/bundle/stop',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const scheduleBundle = (cancelTokenSource, bundleRedisID, engine, schedule) => {
    let formData = new FormData();
    if (bundleRedisID !== '') {
        formData.append('bundleRedisID', bundleRedisID);
    }
    if (engine !== '') {
        formData.append('engine', engine);
    }
    if (schedule !== '') {
        formData.append('schedule', schedule);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/bundle/schedule',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const unscheduleBundle = (cancelTokenSource, bundleRedisID) => {
    let formData = new FormData();
    if (bundleRedisID !== '') {
        formData.append('bundleRedisID', bundleRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/bundle/unschedule',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const processBundleStatusesList = (bundleStatuses) => {
    bundleStatuses = addCustomDataToBundleStatuses(bundleStatuses)

    return bundleStatuses
};

export const addCustomDataToBundleStatuses = (bundleStatuses) => {
    return bundleStatuses.map(function (el) {
        let o = Object.assign({}, el);

        o.customStartTime = el['start-time'] ? formatFullDate(el['start-time']) : '';
        o.customCompletionTime = el['completion-time'] ? formatFullDate(el['completion-time']) : '';
        return o;
    });
}

export const processBundleStatusTasksList = (tasks) => {
    tasks = addCustomDataToBundleStatuses(tasks)

    return tasks
};

export const addCustomDataToBundleStatusTasks = (tasks) => {
    return tasks.map(function (el) {
        let o = Object.assign({}, el);

        o.customStartTime = el['start-time'] ? formatFullDate(el['start-time']) : '';
        o.customCompletionTime = el['completion-time'] ? formatFullDate(el['completion-time']) : '';
        return o;
    });
}

//
export const setDependencyOfArtefactsToNull = (artefacts) => {
    return artefacts.map(function (el) {
        let o = Object.assign({}, el);

        o.dependency = null;
        return o;
    });
}
