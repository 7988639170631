//React imports
import React, { useState, useEffect } from 'react';
import { useHistory, Prompt } from 'react-router-dom';
//---

//CSS imports
import './Model.css'
//---

//PrimeReact imports
import { Fieldset } from 'primereact/fieldset';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
//---

//Vendors imports
import axios from 'axios';
//---

//Images imports
import { DiGitBranch } from 'react-icons/di';
import { DiGitMerge } from 'react-icons/di';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import CodeEditor from '../../components/CodeEditor';
import DbtJobExec from '../../components/transform/DbtJobExec';
import { SkeletonCodeEditor } from '../../components/skeletons/SkeletonCodeEditor';
//---

//Utils imports
import { unparseDashInPath } from '../../utils/Parser';
//---

//Data requests imports
import {
    readDbtModel,
    openDbtModel,
    closeDbtModel,
    updateDbtModel,
    validateDbtModel,
    mergeDbtModel,
    formatDbtModelNameForCommand,
    deleteDbtModel,
    checkDbtModelLock
} from '../../data/DbtModelData';
import {
    getAecProjectJobState
} from '../../data/JobData';
import {
    getAuthtokenClaim
} from '../../data/LoginData';
import {
    defaultDbtModel,
    defaultDbtJobState
} from '../../data/DefaultStates';
//---

const Model = ({ projectName, modelName, readOnly = true }) => {
    const history = useHistory();

    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    const aceEditorSQLRef = React.useRef();
    const aceEditorYAMLRef = React.useRef();
    const aceEditorMDRef = React.useRef();

    const [dbtModel, setDbtModel] = useState(defaultDbtModel);

    const [workDir, setWorkDir] = useState('');

    const [dbtFileSessionRedisID, setDbtFileSessionRedisID] = useState('');

    const [expandedEditor, setExpandedEditor] = useState('sql-editor');

    const [saveIsLoading, setSaveIsLoading] = useState(false);

    const [changeSaved, setChangeSaved] = useState(true);

    const [changeApplied, setChangeApplied] = useState(true);

    const [jobErrorDialogIsOpen, setJobErrorDialogIsOpen] = useState(false);

    const [dbtJobState, setDbtJobState] = useState(defaultDbtJobState);

    const [validateAndApplyIsLoading, setValidateAndApplyIsLoading] = useState(false);

    useEffect(() => {
        if (readOnly) {
            readDbtModelCtlr(projectName, modelName);
        } else {
            openDbtModelCtlr(projectName, modelName)
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dbtFileSessionRedisID !== '') {
            if (aceEditorSQLRef.current && aceEditorYAMLRef.current && aceEditorMDRef.current) {
                aceEditorSQLRef.current.editor.resize()
                aceEditorYAMLRef.current.editor.resize()
                aceEditorMDRef.current.editor.resize()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandedEditor]);

    useEffect(() => {
        if (!changeApplied) {
            window.onbeforeunload = () => true
        }
        return () => {
            window.onbeforeunload = undefined
        }
    }, [changeApplied]);

    const readDbtModelCtlr = (projectName, modelName) => {
        readDbtModel(cancelTokenSource, projectName, unparseDashInPath(modelName)).then(
            data => {
                if (data.dbtModel) {
                    setDbtModel(data.dbtModel);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const openDbtModelCtlr = (projectName, modelName) => {
        openDbtModel(cancelTokenSource, projectName, unparseDashInPath(modelName)).then(
            data => {
                if (data.dbtModelInWorks) {
                    setDbtModel(data.dbtModelInWorks.dbtModel);
                    setWorkDir(data.dbtModelInWorks.workDir);
                    setDbtFileSessionRedisID(data.dbtModelInWorks.dbtFileSessionRedisID);
                }
            },
            fullData => {
                showNotification('error', 'Error', fullData.message, 6000);
                if (fullData.data && fullData.data.dbtModelInWorks && fullData.data.dbtModelInWorks.dbtFileSessionRedisID) {
                    let message = <div>
                        <span style={{ color: 'red' }}>{fullData.message}</span>
                        <br />
                        <br />
                        An error occurred while loading the session, it seems corrupted.<br />
                        Do you want to close it ?
                    </div>

                    confirmDialog({
                        style: { maxWidth: '740px' },
                        header: 'Close session',
                        message: message,
                        icon: 'pi pi-info-circle',
                        acceptClassName: 'p-button-danger',
                        accept: () => closeDbtModelCtlr(fullData.data.dbtModelInWorks.dbtFileSessionRedisID),
                    })
                }
            }
        );
    }

    const closeDbtModelCtlr = (dbtFileSessionRedisID) => {
        closeDbtModel(cancelTokenSource, projectName, dbtFileSessionRedisID).then(
            () => {
                setChangeApplied(true) //to avoid the warning message at the exit of the page
                showNotification('success', 'Success', 'changes successfully cancelled', 6000)
                history.replace('/' + projectName + '/transform')
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const updateDbtModelCtlr = (projectName, modelName, dbtFileSessionRedisID) => {
        setSaveIsLoading(true)

        let _dbtModel = {
            name: modelName,
            SQLFileContent: aceEditorSQLRef.current.editor.getValue(),
            YMLFileContent: aceEditorYAMLRef.current.editor.getValue(),
            MDFileContent: aceEditorMDRef.current.editor.getValue(),
        };

        let dbtModelText = '';

        try {
            dbtModelText = JSON.stringify(_dbtModel);
        } catch (_) {
            showNotification('error', 'Error', 'invalid files', 6000)
            return;
        }

        updateDbtModel(cancelTokenSource, projectName, modelName, dbtModelText, dbtFileSessionRedisID).then(
            data => {
                if (data.dbtModel) {
                    setDbtModel(data.dbtModel);
                    setChangeSaved(true);
                    setSaveIsLoading(false);
                    showNotification('success', 'Success', 'model successfully updated', 6000)
                }
            },
            errorMessage => {
                setSaveIsLoading(false);
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const validateDbtModelCtlr = (projectName, modelName, dbtFileSessionRedisID) => {
        setValidateAndApplyIsLoading(true);
        validateDbtModel(cancelTokenSource, projectName, modelName, dbtFileSessionRedisID).then(
            data => {
                getAecProjectJobStateCtlr(data.jobID);
            },
            errorMessage => {
                setValidateAndApplyIsLoading(false);
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const getAecProjectJobStateCtlr = (jobID) => {
        getAecProjectJobState(cancelTokenSource, jobID).then(
            data => {
                if (data.jobState) {
                    setDbtJobState(data.jobState);
                    if (data.jobState.status === 'completed' || data.jobState.status === 'error') {
                        if (data.jobState.data.appError) {
                            showNotification('error', 'Error', data.jobState.data.appError.message, 6000);
                            setJobErrorDialogIsOpen(true);
                            setValidateAndApplyIsLoading(false);
                        } else {
                            mergeDbtModelCtlr(projectName, dbtModel.name, dbtFileSessionRedisID)
                        }
                    } else {
                        setTimeout(getAecProjectJobStateCtlr, 2000, jobID);
                    }
                }
            },
            errorMessage => {
                setValidateAndApplyIsLoading(false);
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const mergeDbtModelCtlr = (projectName, modelName, dbtFileSessionRedisID) => {
        mergeDbtModel(cancelTokenSource, projectName, modelName, dbtFileSessionRedisID).then(
            () => {
                setChangeApplied(true);
                showNotification('success', 'Success', 'model successfully merged', 6000);
                history.replace('/' + projectName + '/transform');
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
                setValidateAndApplyIsLoading(false);
            }
        );
    }

    const checkAndDeleteDbtModelCtlr = (modelName, dbtFileSessionRedisID) => {
        checkDbtModelLock(cancelTokenSource, projectName, modelName, dbtFileSessionRedisID).then(
            data => {
                if (data.dbtFileSession) {
                    let sub = getAuthtokenClaim('sub')
                    if (sub) {
                        if (sub !== data.dbtFileSession['locked-by']) {
                            let message = <div>
                                The user <span style={{ fontWeight: 'bold' }}>{data.dbtFileSession['locked-by']}</span>
                                &nbsp;currently has a work session open on this model.<br />
                                If you delete the model, his current work will be lost.
                            </div>

                            confirmDialog({
                                header: 'Delete',
                                message: message,
                                icon: 'pi pi-info-circle',
                                acceptClassName: 'p-button-danger',
                                accept: () => deleteDbtModelCtlr(modelName),
                            });

                        } else {
                            deleteDbtModelCtlr(modelName);
                        }
                    } else {
                        showNotification('error', 'Error', 'invalid token, try to log in again or contact an administrator', 6000)
                        return;
                    }
                } else {
                    deleteDbtModelCtlr(modelName);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const checkAndCloseDbtModelCtlr = (modelName, dbtFileSessionRedisID) => {
        checkDbtModelLock(cancelTokenSource, projectName, modelName, dbtFileSessionRedisID).then(
            data => {
                if (data.dbtFileSession) {
                    let sub = getAuthtokenClaim('sub')
                    if (sub) {
                        if (sub !== data.dbtFileSession['locked-by']) {
                            let message = <div>
                                The user <span style={{ fontWeight: 'bold' }}>{data.dbtFileSession['locked-by']}</span>
                                &nbsp;currently has a work session open on this model.<br />
                                If you cancel the model, his current work will be lost.
                            </div>

                            confirmDialog({
                                header: 'Delete',
                                message: message,
                                icon: 'pi pi-info-circle',
                                acceptClassName: 'p-button-danger',
                                accept: () => closeDbtModelCtlr(dbtFileSessionRedisID),
                            });
                        } else {
                            closeDbtModelCtlr(dbtFileSessionRedisID);
                        }
                    } else {
                        showNotification('error', 'Error', 'invalid token, try to log in again or contact an administrator', 6000)
                        return;
                    }
                } else {
                    closeDbtModelCtlr(dbtFileSessionRedisID);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const deleteDbtModelCtlr = (modelName) => {
        deleteDbtModel(cancelTokenSource, projectName, modelName).then(
            () => {
                setChangeApplied(true) //to avoid the warning message at the exit of the page
                showNotification('success', 'Success', 'model successfully deleted', 6000)
                history.replace('/' + projectName + '/transform')
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const onDelete = () => {
        let message = <div>
            Do you want to delete the <span style={{ fontWeight: 'bold' }}>{dbtModel.name}</span> model ?
        </div>

        confirmDialog({
            header: 'Delete',
            message: message,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => checkAndDeleteDbtModelCtlr(dbtModel.name, dbtFileSessionRedisID),
        });
    }

    const onCancel = () => {
        let message = <div>
            All your changes will be cancelled, do you want to continue ?
        </div>

        confirmDialog({
            header: 'Cancel session',
            message: message,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => checkAndCloseDbtModelCtlr(dbtModel.name, dbtFileSessionRedisID),
        });
    }

    const onClickExpand = (editorToExpand) => {
        setExpandedEditor(editorToExpand)
    }

    const handleOnChangeSQL = (value) => {
        //This is an old version of the code. The new version (not commented) has been implemented, because :
        //For some reason, when saving with shortcuts (Ctrl + S / Cmd + S), the function
        //"aceEditorXXXXRef.current.editor.getValue()" does not return the current editor value. 
        //Also, when using shortcuts, the function that is called afterwards does not have access to the last updated values of the state 
        //of this component, again I don't know why.
        //I specify that the use of the function "aceEditorXXXXRef.current.editor.getValue()" works very well 
        //when activated by the Save button and not the shortcuts.
        //In order to allow saving via shortcuts, we update the editor state.
        //For the moment in test phase, hoping that the state set at each change will not affect the performance.
        /*if (changeSaved) {
            setChangeSaved(false)
            setChangeApplied(false)
            //Due to the call to onChange to detect a code change, the first code input is not taken in consideration. 
            //This is why the first one is set through the state.
            setDbtModel({
                ...dbtModel,
                SQLFileContent: value
            })
        }*/

        if (changeSaved) {
            setChangeSaved(false)
            setChangeApplied(false)
        }
        setDbtModel({
            ...dbtModel,
            SQLFileContent: value
        })
    }

    const handleOnChangeYML = (value) => {
        //This is an old version of the code. The new version (not commented) has been implemented, because :
        //For some reason, when saving with shortcuts (Ctrl + S / Cmd + S), the function
        //"aceEditorXXXXRef.current.editor.getValue()" does not return the current editor value. 
        //Also, when using shortcuts, the function that is called afterwards does not have access to the last updated values of the state 
        //of this component, again I don't know why.
        //I specify that the use of the function "aceEditorXXXXRef.current.editor.getValue()" works very well 
        //when activated by the Save button and not the shortcuts.
        //In order to allow saving via shortcuts, we update the editor state.
        //For the moment in test phase, hoping that the state set at each change will not affect the performance.
        /*if (changeSaved) {
            setChangeSaved(false)
            setChangeApplied(false)
            //Due to the call to onChange to detect a code change, the first code input is not taken in consideration. 
            //This is why the first one is set through the state.
            setDbtModel({
                ...dbtModel,
                YMLFileContent: value
            })
        }*/

        if (changeSaved) {
            setChangeSaved(false)
            setChangeApplied(false)
        }
        setDbtModel({
            ...dbtModel,
            YMLFileContent: value
        })
    }

    const handleOnChangeMD = (value) => {
        //This is an old version of the code. The new version (not commented) has been implemented, because :
        //For some reason, when saving with shortcuts (Ctrl + S / Cmd + S), the function
        //"aceEditorXXXXRef.current.editor.getValue()" does not return the current editor value. 
        //Also, when using shortcuts, the function that is called afterwards does not have access to the last updated values of the state 
        //of this component, again I don't know why.
        //I specify that the use of the function "aceEditorXXXXRef.current.editor.getValue()" works very well 
        //when activated by the Save button and not the shortcuts.
        //In order to allow saving via shortcuts, we update the editor state.
        //For the moment in test phase, hoping that the state set at each change will not affect the performance.
        /*if (changeSaved) {
            setChangeSaved(false)
            setChangeApplied(false)
            //Due to the call to onChange to detect a code change, the first code input is not taken in consideration. 
            //This is why the first one is set through the state.
            setDbtModel({
                ...dbtModel,
                MDFileContent: value
            })
        }*/

        if (changeSaved) {
            setChangeSaved(false)
            setChangeApplied(false)
        }
        setDbtModel({
            ...dbtModel,
            MDFileContent: value
        })
    }

    const buttonItems = [
        {
            label: 'Cancel session',
            icon: 'pi pi-history',
            disabled: (readOnly || validateAndApplyIsLoading) ? true : false,
            command: onCancel
        },
        {
            label: 'Delete',
            icon: 'pi pi-times',
            disabled: (readOnly || validateAndApplyIsLoading) ? true : false,
            command: onDelete
        }
    ]

    const modelHeader = () => {
        const leftContents = (
            <React.Fragment>
                <DbtJobExec
                    title='Run transformation'
                    projectName={projectName}
                    defaultCommand='run'
                    defaultArgs={`--models ${formatDbtModelNameForCommand(dbtModel.name)}`}
                    className='p-mr-2'
                    workDir={workDir}
                />
                <i className="pi pi-bars p-toolbar-separator p-mr-2" />
                {
                    readOnly ?
                        null :
                        <SplitButton
                            label="Save"
                            icon="pi pi-save"
                            className="p-mr-2 p-ml-2"
                            onClick={() => updateDbtModelCtlr(projectName, dbtModel.name, dbtFileSessionRedisID)}
                            disabled={readOnly || validateAndApplyIsLoading || saveIsLoading}
                            model={buttonItems}
                        />
                }
                {
                    readOnly ?
                        null :
                        <Button
                            label="Validate & Apply"
                            icon={<DiGitMerge className='di-git-merge-icon p-mr-2' />}
                            className="p-button p-mr-2"
                            loading={validateAndApplyIsLoading}
                            onClick={() => validateDbtModelCtlr(projectName, dbtModel.name, dbtFileSessionRedisID)}
                            disabled={readOnly || !changeSaved}
                        />
                }
                {
                    readOnly ?
                        <Button
                            label="Delete"
                            icon="pi pi-times"
                            className="p-button-outlined p-button-danger"
                            onClick={onDelete}
                        /> :
                        null
                }
                {
                    (dbtJobState.status === 'completed' || dbtJobState.status === 'error') && dbtJobState.data.appError ?
                        <Button
                            icon="pi pi-info-circle"
                            className="p-button-rounded p-button-danger p-button-text"
                            onClick={() => setJobErrorDialogIsOpen(true)}
                        /> :
                        null
                }
            </React.Fragment>
        );

        return (
            <Toolbar
                left={
                    <div className='toolbar-left'>
                        {readOnly ? null : <DiGitBranch className='di-git-branch-icon p-mr-2' />}
                        <div style={{ fontWeight: '600' }}>{dbtModel.name}</div>
                    </div>
                }
                right={leftContents}
            />
        )
    }

    return (
        <div className='model'>
            <ConfirmDialog />
            <Fieldset>
                {modelHeader()}
                <div className='code-editors-container '>
                    {
                        (dbtFileSessionRedisID !== '' || readOnly) ?
                            <CodeEditor
                                className={expandedEditor === 'sql-editor' ? 'expanded-editor' : 'second-editor'}
                                mode='sql'
                                editorName='ace-editor-sql'
                                onClickActionButton={() => onClickExpand('sql-editor')}
                                reactRef={aceEditorSQLRef}
                                title='SQL'
                                displayActionButton={expandedEditor === 'sql-editor' ? false : true}
                                actionButtonIcon='pi pi-window-maximize'
                                value={dbtModel.SQLFileContent}
                                onChange={handleOnChangeSQL}
                                readOnly={readOnly}
                                onSave={() => updateDbtModelCtlr(projectName, unparseDashInPath(modelName), dbtFileSessionRedisID)}
                            /> :
                            <SkeletonCodeEditor className={expandedEditor === 'sql-editor' ? 'expanded-editor' : 'second-editor'} />
                    }
                    {
                        (dbtFileSessionRedisID !== '' || readOnly) ?
                            <CodeEditor
                                className={expandedEditor === 'yaml-editor' ? 'expanded-editor' : expandedEditor === 'md-editor' ? 'third-editor' : 'second-editor'}
                                mode='yaml'
                                editorName='ace-editor-yaml'
                                onClickActionButton={() => onClickExpand('yaml-editor')}
                                reactRef={aceEditorYAMLRef}
                                title='YML'
                                displayActionButton={expandedEditor === 'yaml-editor' ? false : true}
                                actionButtonIcon='pi pi-window-maximize'
                                value={dbtModel.YMLFileContent}
                                onChange={handleOnChangeYML}
                                readOnly={readOnly}
                                onSave={() => updateDbtModelCtlr(projectName, unparseDashInPath(modelName), dbtFileSessionRedisID)}
                            /> :
                            <SkeletonCodeEditor className={expandedEditor === 'yaml-editor' ? 'expanded-editor' : expandedEditor === 'md-editor' ? 'third-editor' : 'second-editor'} />
                    }
                    {
                        (dbtFileSessionRedisID !== '' || readOnly) ?
                            <CodeEditor
                                className={expandedEditor === 'md-editor' ? 'expanded-editor' : 'third-editor'}
                                mode='markdown'
                                editorName='ace-editor-md'
                                onClickActionButton={() => onClickExpand('md-editor')}
                                reactRef={aceEditorMDRef}
                                title='MD'
                                displayActionButton={expandedEditor === 'md-editor' ? false : true}
                                actionButtonIcon='pi pi-window-maximize'
                                value={dbtModel.MDFileContent}
                                onChange={handleOnChangeMD}
                                readOnly={readOnly}
                                onSave={() => updateDbtModelCtlr(projectName, unparseDashInPath(modelName), dbtFileSessionRedisID)}
                            /> :
                            <SkeletonCodeEditor className={expandedEditor === 'md-editor' ? 'expanded-editor' : 'third-editor'} />
                    }
                </div>
            </Fieldset>
            <Dialog
                header='Error'
                className='job-error-dialog'
                visible={jobErrorDialogIsOpen}
                maximizable
                modal
                style={{ width: '50vw' }}
                onHide={() => setJobErrorDialogIsOpen(false)}
            >
                <CommandOutput output={dbtJobState.data ? dbtJobState.data.output : ''} />
            </Dialog>

            <Prompt
                when={!changeApplied}
                message={
                    !changeSaved ?
                        'You have unsaved changes, are you sure you want to leave ?' :
                        'You have unapplied changes, if you leave and someone works on that model, your work will be lost. Do you want to leave ?'
                }
            />
        </div>
    );
};

const CommandOutput = props => {
    return <code className='command-output'>{props.output.split('\n').map((str, index) => <div key={index}>{str}</div>)}</code>
}

export default Model;
