//Vendors imports
import axios from 'axios';
//---

//Utils imports
import { log } from '../utils/Log';
//---

const AEC_API_URL = process.env.REACT_APP_AEC_API_URL;

export const listAvailableSingerConnections = (cancelTokenSource) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: AEC_API_URL + '/api/v1/singer-connection/available/list',
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const readAvailableSingerConnections = (cancelTokenSource, singerConnectionName) => {
    let formData = new FormData();
    if (singerConnectionName !== '') {
        formData.append('singerConnectionName', singerConnectionName);
    }


    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/singer-connection/available/read',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const createSingerConnection = (cancelTokenSource, projectName, singerConnection) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        if (singerConnection !== '') {
            formData.append('singerConnection', singerConnection);
        }

        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/singer-connection/create/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const listSingerConnections = (cancelTokenSource, projectName) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: AEC_API_URL + '/api/v1/singer-connection/list/' + projectName,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const readSingerConnection = (cancelTokenSource, projectName, singerConnectionName) => {
    let formData = new FormData();
    if (singerConnectionName !== '') {
        formData.append('singerConnectionName', singerConnectionName);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/singer-connection/read/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                } else if (axios.isCancel(error)) {
                    return;
                }
                reject('request failed');
            });
    });
};

export const updateSingerConnection = (cancelTokenSource, projectName, singerConnectionName, singerConnection) => {
    let formData = new FormData();
    if (singerConnectionName !== '') {
        formData.append('singerConnectionName', singerConnectionName);
    }

    if (singerConnection) {
        formData.append('singerConnection', singerConnection);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'PUT',
            url: AEC_API_URL + '/api/v1/singer-connection/update/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const deleteSingerConnection = (cancelTokenSource, projectName, singerConnectionName) => {
    let formData = new FormData();
    if (singerConnectionName !== '') {
        formData.append('singerConnectionName', singerConnectionName);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'DELETE',
            url: AEC_API_URL + '/api/v1/singer-connection/delete/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};
