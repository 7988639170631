//React imports
import React, { useState, useEffect, useRef } from 'react';
//---

//CSS imports
import '../flow/FlowJobLogs.css'
//---

//PrimeReact imports
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataScroller } from 'primereact/datascroller';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../NotificationProvider';
import SkeletonLogs from '../skeletons/SkeletonLogs';
//---

//Data requests imports
import {
    getTaskContainerLogs,
    getTaskApplicationLogs
} from '../../data/TaskData';
//--


const TaskLogs = ({ task, onHide, logsType = 'technical' }) => {
    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    const ds = useRef(null);

    const [taskLogs, setTaskLogs] = useState([]);

    const [dsInitialized, setDsInitialized] = useState(false);

    const [logsLoading, setLogsLoading] = useState(false);

    useEffect(() => {
        getTaskLogsCtlr(task['redis-id'])

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dsInitialized) {
            ds.current.reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskLogs]);

    const getTaskLogsCtlr = (taskRedisID) => {
        setLogsLoading(true);
        if (logsType === 'application') {
            getTaskApplicationLogs(cancelTokenSource, taskRedisID).then(
                data => {
                    if (data.taskLogs) {
                        setTaskLogs(data.taskLogs);
                    }
                    setLogsLoading(false);
                    setDsInitialized(true);
                },
                errorMessage => {
                    //A problem due to misconfiguration of the task probably occurred before the folder and clever cloud app were created. 
                    //There are no logs, because the app does not exist on the clever cloud side
                    if (!errorMessage.endsWith('no such file or directory')) {
                        showNotification('error', 'Error', errorMessage, 6000);
                    }
                    setLogsLoading(false);
                    setDsInitialized(true);
                }
            );
        } else {
            getTaskContainerLogs(cancelTokenSource, taskRedisID).then(
                data => {
                    if (data.taskLogs) {
                        setTaskLogs(data.taskLogs);
                    }
                    setLogsLoading(false);
                    setDsInitialized(true);
                },
                errorMessage => {
                    //A problem due to misconfiguration of the task probably occurred before the folder and clever cloud app were created. 
                    //There are no logs, because the app does not exist on the clever cloud side
                    if (!errorMessage.endsWith('no such file or directory')) {
                        showNotification('error', 'Error', errorMessage, 6000);
                    }
                    setLogsLoading(false);
                    setDsInitialized(true);
                }
            );
        }
        
    }

    const basicItemTemplate = (data) => {
        return (
            <div className='log-container'>
                <span className="p-mr-2 log-timestamp">{data.timestamp}</span>
                <span className="p-mr-2 log-payload">{`${data.payload}`}</span>
            </div>
        );
    }

    return (
        <div>
            <Dialog
                className='task-job-logs'
                header={`${logsType} logs - ${task.id}`}
                visible={true}
                maximizable
                modal
                style={{ width: '80vw' }}
                onHide={onHide}
            >
                <div className='p-pt-2'>
                    <Button
                        label="Refresh"
                        icon="pi pi-refresh"
                        className="p-button-outlined p-mb-2"
                        loading={logsLoading}
                        onClick={() => getTaskLogsCtlr(task['redis-id'])}
                    />
                </div>
                <div>
                    <div className='p-pt-2' style={{ display: logsLoading ? 'block' : 'none' }}><SkeletonLogs /></div>
                    <div style={{ display: logsLoading ? 'none' : 'block' }}>
                        <DataScroller
                            ref={ds}
                            value={taskLogs}
                            itemTemplate={basicItemTemplate}
                            rows={taskLogs.length}
                            inline
                        />
                    </div>
                </div>

            </Dialog>
        </div>
    );
};


export default TaskLogs;