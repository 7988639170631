//React imports
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
//---

//CSS imports
import './PutFlow.css'
//---

//PrimeReact imports
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Steps } from 'primereact/steps';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
//---

//Vendors imports
import axios from 'axios';
//---

//Images imports
import { ImMoveDown } from 'react-icons/im';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import { GlobalAecProjectStateContext } from '../../components/GlobalAecProjectStateProvider';
import ConnectorSelection from '../../components/connector/ConnectorSelection';
import ConnectorForm from '../../components/connector/ConnectorForm';
//---

//Data requests imports
import {
    listAvailableSingerConnectors
} from '../../data/SingerConnectorData';

import {
    createSingerFlow,
    readSingerFlow,
    updateSingerFlow
} from '../../data/SingerFlowData';

import {
    listSingerConnections,
} from '../../data/SingerConnectionData';
//--

const PutFlow = ({ projectName, mode }) => {
    const { aecProject } = useContext(GlobalAecProjectStateContext);

    const history = useHistory();

    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    const [connections, setConnections] = useState([]);
    const [readerConnection, setReaderConnection] = useState({})
    const [writerConnection, setWriterConnection] = useState({})

    const [availableConnectors, setAvailableConnectors] = useState({});
    const [reader, setReader] = useState({});
    const [writer, setWriter] = useState({});

    const [flow, setFlow] = useState({
        readerData: '',
        writerData: ''
    })

    const [existingFlow, setExistingFlow] = useState(null)

    const [displayFinalDialog, setDisplayFinalDialog] = useState(false);

    const defaultValues = {
        flowName: '',
        flowDescription: '',
    }

    const { control, formState: { errors }, handleSubmit, setValue } = useForm({ defaultValues });

    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const steps = [
        {
            title: 'Start by choosing one of your connections to see the compatible readers',
            label: 'Choose a reader',
            disabled: mode === 'update' ? true : false
        },
        {
            title: 'Fill out the form to configure the reader',
            label: 'Configure the reader',
            disabled: activeStepIndex < 1 ? true : false
        },
        {
            title: 'Choose one of your connections to see the compatible writers',
            label: 'Choose a writer',
            disabled: mode === 'update' ? true : (activeStepIndex < 2 ? true : false)
        },
        {
            title: 'Fill out the form to configure the writer',
            label: 'Configure the writer',
            disabled: activeStepIndex < 3 ? true : false
        }
    ];

    useEffect(() => {
        if (aecProject.name) {
            listSingerConnectionsCtlr();
            listAvailableSingerConnectorsCtlr();
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aecProject]);

    useEffect(() => {
        if (mode === 'update') {
            if (connections.length > 0 && availableConnectors['available-taps'] && availableConnectors['available-targets']) {
                let _urlSearchParams = new URLSearchParams(history.location.search);
                let flowName = _urlSearchParams.get("flow-name")
                if (flowName) {
                    readSingerFlowCtlr(flowName)
                }
            }
        }

        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connections, availableConnectors]);

    const listSingerConnectionsCtlr = () => {
        listSingerConnections(cancelTokenSource, projectName).then(
            data => {
                if (data.singerConnections) {
                    setConnections(data.singerConnections);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const listAvailableSingerConnectorsCtlr = () => {
        listAvailableSingerConnectors(cancelTokenSource).then(
            data => {
                if (data.singerConnectors) {
                    setAvailableConnectors(data.singerConnectors);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const readSingerFlowCtlr = (singerFlowName) => {
        readSingerFlow(cancelTokenSource, projectName, singerFlowName).then(
            data => {
                if (data.singerFlow) {
                    let _readerConnection = connections.find(conn =>
                        conn.name === data.singerFlow['singer-tap'].connection.name);

                    let _writerConnection = connections.find(conn =>
                        conn.name === data.singerFlow['singer-target'].connection.name);

                    let _reader = availableConnectors['available-taps'].find(tap =>
                        tap.name === data.singerFlow['singer-tap'].name);

                    let _writer = availableConnectors['available-targets'].find(target =>
                        target.name === data.singerFlow['singer-target'].name);

                    if (_readerConnection && _writerConnection && _reader && _writer) {
                        setValue('flowName', data.singerFlow.name);
                        setValue('flowDescription', data.singerFlow.description);
                        setReaderConnection(_readerConnection);
                        setWriterConnection(_writerConnection);
                        setReader(_reader);
                        setWriter(_writer);
                        setExistingFlow(data.singerFlow);
                        setActiveStepIndex(1);
                    }
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const onSelectReader = (selectedConnection, selectedConnector) => {
        setReaderConnection(selectedConnection)
        setReader(selectedConnector)
        setActiveStepIndex(1)
    }

    const onCreateReader = (connectorData) => {
        setFlow({ ...flow, readerData: connectorData })
        if (mode === 'update') {
            setActiveStepIndex(3)
        } else {
            setActiveStepIndex(2)
        }
    }

    const onSelectWriter = (selectedConnection, selectedConnector) => {
        setWriterConnection(selectedConnection)
        setWriter(selectedConnector)
        setActiveStepIndex(3)
    }

    const onCreateWriter = (connectorData) => {
        setFlow({ ...flow, writerData: connectorData })
        setDisplayFinalDialog(true)
    }

    const createFlowCtlr = (formData) => {
        let singerFlow = {
            'name': formData.flowName,
            'description': formData.flowDescription,
            'singer-tap': flow.readerData,
            'singer-target': flow.writerData,
        };

        let _singerFlow = ''

        try {
            _singerFlow = JSON.stringify(singerFlow);
        } catch (_) {
            showNotification('error', 'Cannot perform', 'the flow data are invalid, please try again or contact an administrator.', 6000)
            return;
        }

        if (mode === 'create') {
            createSingerFlowCtlr(_singerFlow);
        } else {
            updateSingerFlowCtlr(existingFlow.name, _singerFlow)
        }
    }

    const createSingerFlowCtlr = (singerFlow) => {
        createSingerFlow(cancelTokenSource, projectName, singerFlow).then(
            data => {
                history.push({
                    pathname: '/' + projectName + '/flows',
                    search: 'search-flow=' + (data.singerFlow ? data.singerFlow.name : 'undefined')
                })
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const updateSingerFlowCtlr = (singerFlowName, singerFlow) => {
        updateSingerFlow(cancelTokenSource, projectName, singerFlowName, singerFlow).then(
            data => {
                showNotification('success', 'Success', 'flow successfully updated', 6000)
                history.push('/' + projectName + "/flows/" + (data.singerFlow ? data.singerFlow.name : 'undefined'))
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className='p-error'>{errors[name].message}</small>
    };

    const renderStep = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <ConnectorSelection
                    connections={connections}
                    availableConnectors={availableConnectors}
                    connectorType='available-taps'
                    storedSelectedConnection={readerConnection}
                    onSelectConnector={onSelectReader}
                />;
            case 1:
                return <ConnectorForm
                    selectedConnection={readerConnection}
                    selectedConnector={reader}
                    onCreateConnector={onCreateReader}
                    mode={mode}
                    formData={existingFlow ? existingFlow['singer-tap'].configuration : {}}
                />;
            case 2:
                return <ConnectorSelection
                    connections={connections}
                    availableConnectors={availableConnectors}
                    connectorType='available-targets'
                    storedSelectedConnection={writerConnection}
                    onSelectConnector={onSelectWriter}
                />;
            case 3:
                return <ConnectorForm
                    selectedConnection={writerConnection}
                    selectedConnector={writer}
                    onCreateConnector={onCreateWriter}
                    mode={mode}
                    formData={existingFlow ? existingFlow['singer-target'].configuration : {}}
                />
            default:
                return 'Unknown stepIndex';
        }
    }

    const renderFooter = () => {
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => setDisplayFinalDialog(false)} className="p-button-text" />
                <Button label={mode === 'create' ? 'Create' : 'Update'} icon="pi pi-check" onClick={handleSubmit(createFlowCtlr)} />
            </div>
        );
    }

    return (
        <div className='put-flow'>
            <Steps model={steps} activeIndex={activeStepIndex} onSelect={(e) => setActiveStepIndex(e.index)} readOnly={false} />
            <div className='p-d-flex p-flex-column p-jc-center p-ai-center'>
                <Avatar
                    className='xxl-avatar p-m-3'
                >
                    <ImMoveDown />
                </Avatar>
                <div className='title'>{steps[activeStepIndex].title}</div>
            </div>

            <div className='p-d-flex p-jc-center p-mb-3 container'>
                {renderStep(activeStepIndex)}
            </div>

            <Dialog
                header="Flow confirmation"
                visible={displayFinalDialog}
                style={{ width: '30vw', minWidth: '400px' }}
                footer={renderFooter()}
                onHide={() => setDisplayFinalDialog(false)}
            >
                <form onSubmit={handleSubmit(createFlowCtlr)} className='p-fluid' autoComplete='off'>
                    <div className='p-field'>
                        <label htmlFor='flowName' className={classNames('p-d-block', { 'p-error': errors.flowName })}>Name *</label>
                        <Controller name='flowName' control={control} rules={{ required: 'Name is required.' }} render={({ field, fieldState }) => (
                            <InputText id={field.name} {...field} autoFocus className={classNames('p-d-block dialog-input', { 'p-invalid': fieldState.invalid })} />
                        )} />
                        <small id="flowName-help" className="p-d-block">Enter the name of your new flow.</small>
                        {getFormErrorMessage('flowName')}
                    </div>
                    <div className='p-field'>
                        <label htmlFor='flowDescription' className={classNames('p-d-block', { 'p-error': errors.flowDescription })}>Description *</label>
                        <Controller name='flowDescription' control={control} rules={{ required: 'Description is required.' }} render={({ field, fieldState }) => (
                            <InputTextarea id={field.name} {...field} className={classNames('dialog-input', { 'p-invalid': fieldState.invalid })}
                                rows={5} cols={30} autoResize
                            />
                        )} />
                        <small id="flowDescription-help" className="p-d-block">Enter a description.</small>
                        {getFormErrorMessage('flowDescription')}
                    </div>
                </form>
            </Dialog>
        </div>
    );
};

export default PutFlow;
