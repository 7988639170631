//React imports
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
//---

//CSS imports
import './DbtFileSessionsTable.css'
//---

//PrimeReact imports
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog } from 'primereact/confirmdialog';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../NotificationProvider';
import FileSessionDiffDialog from './FileSessionDiffDialog';
//---

//Utils imports
import { parseDashInPath } from '../../utils/Parser';
//---

//Data requests imports
import {
    listDbtFileSessions,
    checkDbtModelLock,
    closeDbtModel,
    compareDbtModel
} from '../../data/DbtModelData';
import {
    checkDbtSourceLock,
    closeDbtSource,
    compareDbtSource
} from '../../data/DbtSourceData';
import {
    checkDbtTestLock,
    closeDbtTest,
    compareDbtTest
} from '../../data/DbtTestData';
import {
    getAuthtokenClaim
} from '../../data/LoginData';
import {
    defaultDbtFileSessionDiff
} from '../../data/DefaultStates';
//---

//Utils imports
import { formatFullDate } from '../../utils/Date';
//---

const DbtFileSessionsTable = ({ projectName, entityTypeFilter = '' }) => {
    const history = useHistory();

    const { showNotification } = useNotification();

    const cancelTokenSource = axios.CancelToken.source();

    const dt = useRef(null);

    const [dbtFileSessions, setDbtFileSessions] = useState([])

    const [filters, setFilters] = useState({});

    const globalFilterInputRef = useRef(null);

    const [isLoading, setIsLoading] = useState(false);

    const [confirmDialogState, setConfirmDialogState] = useState({
        visible: false,
        message: '',
        footer: null
    });

    const [expandedRows, setExpandedRows] = useState(null);

    const [dbtFileSessionDiff, setDbtFileSessionDiff] = useState(defaultDbtFileSessionDiff);
    //const [dbtFileSessionDiff, setDbtFileSessionDiff] = useState(null);

    useEffect(() => {
        listDbtFileSessionsCtlr();
        initFilters();

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initFilters = () => {
        setFilters({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'entity-name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            'last-modified-by': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            'last-modified-time': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            'status': { value: 'opened', matchMode: FilterMatchMode.CONTAINS }
        })
        globalFilterInputRef.current.value = '';
    }

    const listDbtFileSessionsCtlr = () => {
        setIsLoading(true);
        listDbtFileSessions(cancelTokenSource, projectName, entityTypeFilter).then(
            data => {
                if (data.dbtFileSessions) {
                    setDbtFileSessions(data.dbtFileSessions);
                    setIsLoading(false);
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
                setIsLoading(false);
            }
        );
    }

    const openDbtWorkSessionCtlr = (projectName, entityType, entityName) => {
        if (entityType === 'model') {
            checkDbtModelLock(cancelTokenSource, projectName, entityName).then(
                data => {
                    if (data.dbtFileSession) {
                        let sub = getAuthtokenClaim('sub')
                        if (sub) {
                            if (sub !== data.dbtFileSession['locked-by']) {
                                displayConfirmDialog(entityType, entityName, data.dbtFileSession)
                            } else {
                                goToEntity(entityType, entityName)
                            }
                        } else {
                            showNotification('error', 'Error', 'invalid token, try to log in again or contact an administrator', 6000)
                            return;
                        }
                    } else {
                        goToEntity(entityType, entityName)
                    }
                },
                errorMessage => showNotification('error', 'Error', errorMessage, 6000)
            );
        } else if (entityType === 'source') {
            checkDbtSourceLock(cancelTokenSource, projectName, entityName).then(
                data => {
                    if (data.dbtFileSession) {
                        let sub = getAuthtokenClaim('sub')
                        if (sub) {
                            if (sub !== data.dbtFileSession['locked-by']) {
                                displayConfirmDialog(entityType, entityName, data.dbtFileSession)
                            } else {
                                goToEntity(entityType, entityName)
                            }
                        } else {
                            showNotification('error', 'Error', 'invalid token, try to log in again or contact an administrator', 6000)
                            return;
                        }
                    } else {
                        goToEntity(entityType, entityName)
                    }
                },
                errorMessage => showNotification('error', 'Error', errorMessage, 6000)
            );
        } else if (entityType === 'test') {
            checkDbtTestLock(cancelTokenSource, projectName, entityName).then(
                data => {
                    if (data.dbtFileSession) {
                        let sub = getAuthtokenClaim('sub')
                        if (sub) {
                            if (sub !== data.dbtFileSession['locked-by']) {
                                displayConfirmDialog(entityType, entityName, data.dbtFileSession)
                            } else {
                                goToEntity(entityType, entityName)
                            }
                        } else {
                            showNotification('error', 'Error', 'invalid token, try to log in again or contact an administrator', 6000)
                            return;
                        }
                    } else {
                        goToEntity(entityType, entityName)
                    }
                },
                errorMessage => showNotification('error', 'Error', errorMessage, 6000)
            );
        }
    }

    const displayConfirmDialog = (entityType, entityName, dbtFileSession) => {
        let message = <div>
            The user <span style={{ fontWeight: 'bold' }}>{dbtFileSession['locked-by']}</span>
            &nbsp;currently has a work session open on this {entityType}.<br />
            You can <span style={{ fontWeight: 'bold' }}>continue</span> his session and take back his code
            or <span style={{ fontWeight: 'bold' }}>overwrite</span> it and start again with a clean code.
        </div>

        setConfirmDialogState({
            visible: true,
            message: message,
            footer: renderOpenConfirmDialogFooter(entityType, entityName, dbtFileSession['redis-id'])
        });
    }

    const closeAndOpenDbtEntityCtlr = (entityType, entityName, dbtFileSessionRedisID) => {
        if (entityType === 'model') {
            closeDbtModel(cancelTokenSource, projectName, dbtFileSessionRedisID).then(
                () => {
                    goToEntity(entityType, entityName)
                },
                errorMessage => showNotification('error', 'Error', errorMessage, 6000)
            );
        } else if (entityType === 'source') {
            closeDbtSource(cancelTokenSource, projectName, dbtFileSessionRedisID).then(
                () => {
                    goToEntity(entityType, entityName)
                },
                errorMessage => showNotification('error', 'Error', errorMessage, 6000)
            );
        } else if (entityType === 'test') {
            closeDbtTest(cancelTokenSource, projectName, dbtFileSessionRedisID).then(
                () => {
                    goToEntity(entityType, entityName)
                },
                errorMessage => showNotification('error', 'Error', errorMessage, 6000)
            );
        }
    }

    const compareDbtEntityCtlr = (entityType, dbtFileSessionRedisID) => {
        if (entityType === 'model') {
            compareDbtModel(cancelTokenSource, projectName, dbtFileSessionRedisID).then(
                (data) => {
                    if (data.dbtModelDiff) {
                        let dbtFileSessionDiff = data.dbtModelDiff
                        dbtFileSessionDiff.entityType = 'model'
                       
                        setDbtFileSessionDiff(dbtFileSessionDiff);
                    }
                },
                errorMessage => showNotification('error', 'Error', errorMessage, 6000)
            );
        } else if (entityType === 'source') {
            compareDbtSource(cancelTokenSource, projectName, dbtFileSessionRedisID).then(
                (data) => {
                    if (data.dbtSourceDiff) {
                        let dbtFileSessionDiff = data.dbtSourceDiff
                        dbtFileSessionDiff.entityType = 'source'
                        setDbtFileSessionDiff(dbtFileSessionDiff);
                    }
                },
                errorMessage => showNotification('error', 'Error', errorMessage, 6000)
            );
        } else if (entityType === 'test') {
            compareDbtTest(cancelTokenSource, projectName, dbtFileSessionRedisID).then(
                (data) => {
                    if (data.dbtTestDiff) {
                        let dbtFileSessionDiff = data.dbtTestDiff
                        dbtFileSessionDiff.entityType = 'test'
                        setDbtFileSessionDiff(dbtFileSessionDiff);
                    }
                },
                errorMessage => showNotification('error', 'Error', errorMessage, 6000)
            );
        }
    }

    const goToEntity = (entityType, entityName) => {
        if (entityType === 'model') {
            history.push('/' + projectName + "/transform/model/open/" + parseDashInPath(entityName) + "/")
        } else if (entityType === 'source') {
            history.push('/' + projectName + "/transform/source/open/" + parseDashInPath(entityName) + "/")
        } else if (entityType === 'test') {
            history.push('/' + projectName + "/test/open/" + parseDashInPath(entityName) + "/")
        }
    }

    const reset = () => {
        //setGlobalFilter('');
        initFilters();
        dt.current.reset();
    }

    const onGlobalFilterSearch = (globalFilterValue) => {
        let _filters = { ...filters };
        _filters['global'].value = globalFilterValue;

        setFilters(_filters);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onGlobalFilterSearch(globalFilterInputRef.current.value);
        }
    }

    const statuses = [
        'opened', 'closed', 'canceled'
    ];

    const entityNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">File</span>
                {
                    rowData['status'] === 'opened' ?
                        <div className='resource-link'
                            onClick={() => openDbtWorkSessionCtlr(projectName, rowData['entity-type'], rowData['entity-name'])}>
                            {rowData['entity-name']}
                        </div> :
                        <div className="text">{rowData['entity-name']}</div>
                }

            </React.Fragment>
        );
    }

    const lastModifiedByBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Last update by</span>
                <div className="text">{rowData['last-modified-by']}</div>
            </React.Fragment>
        );
    }

    const lastModifiedTimeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Last update at</span>
                <div className="text">{formatFullDate(rowData['last-modified-time'])}</div>
            </React.Fragment>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return <span className={`customer-badge status-${rowData['status']}`}>{rowData['status']}</span>;
    }

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{option}</span>;
    }

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select a Status" className="p-column-filter" showClear />;
    }

    const header = (
        <div className="table-header">
            <div className='p-d-flex p-ai-center'>
                <h5 className="p-mr-3 p-ml-0 p-mt-0 p-mb-0">Work sessions</h5>
                <div>
                    <span className="p-input-icon-left p-mr-2">
                        <i className="pi pi-search" />
                        <InputText type="search" ref={globalFilterInputRef} placeholder="Global Search" className="searchbox" onKeyDown={handleKeyDown} />
                        <Button type="button" icon="pi pi-search" className="p-button-outlined p-ml-2" onClick={() => onGlobalFilterSearch(globalFilterInputRef.current.value)} />
                    </span>
                    <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={reset} />
                </div>
            </div>
            <Button type="button" label="Refresh" className="p-button-outlined" icon="pi pi-replay"
                onClick={() => listDbtFileSessionsCtlr()} loading={isLoading} />
        </div>
    );

    const renderOpenConfirmDialogFooter = (entityType, entityName, dbtFileSessionRedisID) => {
        return (
            <div>
                <Button label="Back" icon="pi pi-arrow-left" className="p-button-text"
                    onClick={() => setConfirmDialogState({ visible: false, message: '', footer: null })} />
                <Button label="Overwrite" icon="pi pi-times" className="p-button-danger"
                    onClick={() => closeAndOpenDbtEntityCtlr(entityType, entityName, dbtFileSessionRedisID)} />
                <Button label="Continue" icon="pi pi-check"
                    onClick={() => goToEntity(entityType, entityName)} />
            </div>
        );
    };

    const eventTimeBodyTemplate = (rowData) => {
        return formatFullDate(rowData['event-time']);
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div>
                <div className="p-grid">
                    <div className="p-col-6">
                        <p><span className="p-text-bold">Type : </span>{data['entity-type']}</p>
                        <p><span className="p-text-bold">Created by : </span>{data['created-by']}</p>
                        <p><span className="p-text-bold">Last update by : </span>{data['last-modified-by']}</p>
                        <p><span className="p-text-bold">Locked by : </span>{data['locked-by']}</p>
                    </div>
                    <div className="p-col-6">
                        <p><span className="p-text-bold">Name : </span>{data['entity-name']}</p>
                        <p><span className="p-text-bold">Created at : </span>{formatFullDate(data['creation-time'])}</p>
                        <p><span className="p-text-bold">Last update at : </span>{formatFullDate(data['last-modified-time'])}</p>
                        <p><span className="p-text-bold">Status : </span>{data['status']}</p>
                    </div>
                    <div className="p-col-12">
                        <p style={{ margin: '-1rem 0rem 0rem 0rem' }}><span className="p-text-bold">Work dir : </span>{data['work-dir']}</p>
                        {data['status'] === 'opened' ?
                            <p>
                                <span className="p-text-bold">Changes : </span>
                                <span className='resource-link' onClick={() => {
                                    compareDbtEntityCtlr(data['entity-type'], data['redis-id'])
                                }}>
                                    Show the differences
                                </span>
                            </p> :
                            null
                        }
                    </div>
                </div>
                <DataTable
                    className='dbt-file-sessions-events'
                    value={data.history}
                    responsiveLayout="scroll"
                    sortField='event-time'
                    sortOrder={-1}>
                    <Column field="event" header="Event"></Column>
                    <Column field="event-time" header="Event time" body={eventTimeBodyTemplate} sortable></Column>
                </DataTable>
            </div>
        );
    }

    return (
        <div className="dbt-file-sessions-table">
            <div className="card">
                <DataTable ref={dt} value={dbtFileSessions} paginator rows={12}
                    header={header} className="p-datatable-dbt-file-sessions-table"
                    selectionMode="single"
                    sortField='last-modified-time'
                    sortOrder={-1}
                    filters={filters}
                    onFilter={(e) => setFilters(e.filters)}
                    filterDisplay="menu"
                    loading={isLoading}
                    responsiveLayout="scroll"
                    emptyMessage="No work sessions found."
                    expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate}>
                    <Column expander={true} style={{ padding: '0rem 1rem' }} />
                    <Column
                        field="entity-name" header="File" body={entityNameBodyTemplate}
                        sortable filter filterPlaceholder="file" filterField="entity-name"
                        showFilterMatchModes={false} showFilterMenuOptions={false}
                    />

                    <Column
                        field="last-modified-by" header="Last update by" body={lastModifiedByBodyTemplate}
                        sortable filter filterPlaceholder="last update by" filterField="last-modified-by"
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column
                        field="last-modified-time" header="Last update at" body={lastModifiedTimeBodyTemplate}
                        sortable filter filterPlaceholder="last update at" filterField="last-modified-time"
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column
                        field="status" header="Status" sortable
                        body={statusBodyTemplate}
                        filter filterField="status" filterElement={statusFilterTemplate}
                        showFilterMatchModes={false} showFilterMenuOptions={false} />
                </DataTable>
            </div>
            <ConfirmDialog
                header='Confirmation'
                message={confirmDialogState.message}
                icon='pi pi-exclamation-triangle'
                footer={confirmDialogState.footer}
                visible={confirmDialogState.visible}
                onHide={() => setConfirmDialogState({
                    visible: false,
                    message: '',
                    footer: null
                })}
            />
            <FileSessionDiffDialog
                fileSessionDiff={dbtFileSessionDiff}
            />
        </div>
    );
};

export default DbtFileSessionsTable;
