//React imports
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
//---

//CSS imports
import './SubMenu.css'
//---

//PrimeReact imports
import { classNames } from 'primereact/utils';
import { Avatar } from 'primereact/avatar';
//---

//Images imports
import { ImMoveDown } from 'react-icons/im';
import { ImMakeGroup } from 'react-icons/im';
import { VscChecklist } from 'react-icons/vsc';

import { ImTree } from 'react-icons/im';
import { DiGit } from 'react-icons/di';
//import { SiJupyter } from 'react-icons/si';
//import { ImTable } from 'react-icons/im';
//import { BiBrain } from 'react-icons/bi';
//---

//Components imports
import { useNotification } from "../NotificationProvider";
import { GlobalAecProjectStateContext } from '../GlobalAecProjectStateProvider';
import SubMenuExternalApp from './SubMenuExternalApp';
import DbtJobExec from '../../components/transform/DbtJobExec';
//---

//Data requests imports
import {
    getAuthtokenExternalApps,
    hasAccessRights
} from '../../data/LoginData';
//---

const SubMenu = ({ pathname }) => {
    const { aecProject } = useContext(GlobalAecProjectStateContext);

    const history = useHistory();

    const { showNotification } = useNotification();

    const goToGettingStarted = () => {
        //history.push('/')
        window.open('https://datatask.notion.site/datatask/DataTask-User-Guide-6846329e4024456587ef36fe270b2d27', '_blank').focus();
    }

    const goTo = (dest) => {
        let projectName = history.location.pathname.split('/')[1]
        if (projectName) {
            history.push('/' + projectName + dest)
        } else {
            showNotification('error', 'Cannot perform', 'try to select a project or log in again.', 6000)
        }
    }

    const decorateAecProjectName = () => {
        let projectName = history.location.pathname.split('/')[1]
        let dProjectName = 'P'
        if (projectName) {
            let prefixEnd = projectName.indexOf('_')
            if (prefixEnd > 0) {
                dProjectName = projectName.slice(prefixEnd + 1).replaceAll("_", " ")
            } else {
                return projectName
            }
        }

        return dProjectName
    }

    const getCurrentPage = () => {
        let currentPage = history.location.pathname.split('/')[2]
        if (currentPage === 'connection' || currentPage === 'connections') {
            return 'connections'
        } else if (currentPage === 'transform') {
            return 'transform'
        } else if (currentPage === 'test') {
            return 'test'
        } else if (currentPage === 'docs') {
            return 'docs'
        } else if (currentPage === 'edr-report') {
            return 'edr-report'
        } else if (currentPage === 'flow' || currentPage === 'flows') {
            return 'flows'
        } else if (currentPage === 'object-storage') {
            return 'object-storage'
        } else if (currentPage === 'data-explorer') {
            return 'data-explorer'
        } else if (currentPage === 'task' || currentPage === 'tasks') {
            return 'tasks'
        } else if (currentPage === 'artefact' || currentPage === 'artefacts') {
            return 'artefacts'
        } else if (currentPage === 'service' || currentPage === 'services') {
            return 'services'
        } else if (currentPage === 'bundle' || currentPage === 'bundles') {
            return 'bundles'
        } else {
            return 'dashboard'
        }
    }

    const renderFlowsMenu = () => {
        if (hasAccessRights('flows')) {
            return (
                <div className='item-render'>
                    <hr />
                    <div
                        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                        onClick={() => goTo('/connections')}
                    >
                        <Avatar
                            className={classNames('p-mr-3', { 'current-page': getCurrentPage() === 'connections' })}
                            icon='pi pi-key'
                        />
                        <div>
                            Connections
                        </div>
                    </div>

                    <div
                        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                        onClick={() => goTo('/flows')}
                    >
                        <Avatar
                            className={classNames('p-mr-3', { 'current-page': getCurrentPage() === 'flows' })}
                        >
                            <ImMoveDown className='im-move-down-icon' />
                        </Avatar>
                        <div>
                            Flows
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderTransformMenu = () => {
        if (hasAccessRights('transform')) {
            return (
                <div className='item-render'>
                    <div
                        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                        onClick={() => goTo('/transform')}
                    >
                        <Avatar
                            className={classNames('p-mr-3', { 'current-page': getCurrentPage() === 'transform' })}
                        >
                            <ImMakeGroup className='im-make-group-icon' />
                        </Avatar>
                        <div>
                            Transform
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderTestMenu = () => {
        if (hasAccessRights('transform')) {
            return (
                <div className='item-render'>
                    <div
                        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                        onClick={() => goTo('/test')}
                    >
                        <Avatar
                            className={classNames('p-mr-3', { 'current-page': getCurrentPage() === 'test' })}
                        >
                            <VscChecklist className='vsc-check-list' />
                        </Avatar>
                        <div>
                            Test
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderDocsMenu = () => {
        if (hasAccessRights('docs')) {
            return (
                <div className='item-render'>
                    <div className='p-d-inline-flex p-ai-center p-mb-2 item-container'>
                        <div
                            className='item p-d-inline-flex p-ai-center'
                            onClick={() => goTo('/docs')}
                        >

                            <Avatar
                                className={classNames('p-mr-3', { 'current-page': getCurrentPage() === 'docs' })}
                                icon='pi pi-book'
                            />

                            <div className='item-name'>
                                Docs
                            </div>
                        </div>

                        <DbtJobExec
                            title='Generate docs'
                            label='Generate docs'
                            icon='pi pi-sync'
                            projectName={aecProject.name}
                            cmd='dbt'
                            defaultCommand='docs'
                            defaultArgs=''
                            buttonType='menu'
                            lockDbtCommand={true}
                        />
                    </div>

                    <div className='p-d-inline-flex p-ai-center p-mb-2 item-container'>
                        <div
                            className='item p-d-inline-flex p-ai-center'
                            onClick={() => goTo('/edr-report')}
                        >

                            <Avatar
                                className={classNames('p-mr-3', { 'current-page': getCurrentPage() === 'edr-report' })}
                                icon='pi pi-check-square'
                            />

                            <div className='item-name'>
                                EDR Report
                            </div>
                        </div>

                        <DbtJobExec
                            title='Generate edr report'
                            label='Generate edr report'
                            icon='pi pi-sync'
                            projectName={aecProject.name}
                            cmd='edr'
                            defaultCommand='report'
                            defaultArgs=''
                            buttonType='menu'
                            lockDbtCommand={true}
                        />
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderEventSchedulerMenu = () => {
        if (hasAccessRights('event-scheduler')) {
            return (
                <div className='item-render'>
                    <hr />
                    <div
                        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                        onClick={() => goTo('/tasks')}
                    >
                        <Avatar
                            className={classNames('p-mr-3', { 'current-page': getCurrentPage() === 'tasks' })}
                            icon='pi pi-caret-right'
                        />
                        <div>
                            Tasks
                        </div>
                    </div>
                    <div
                        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                        onClick={() => goTo('/artefacts')}
                    >
                        <Avatar
                            className={classNames('p-mr-3', { 'current-page': getCurrentPage() === 'artefacts' })}
                            icon='pi pi-box'
                        />
                        <div>
                            Artefacts
                        </div>
                    </div>
                    <div
                        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                        onClick={() => goTo('/services')}
                    >
                        <Avatar
                            className={classNames('p-mr-3', { 'current-page': getCurrentPage() === 'services' })}
                            icon='pi pi-globe'
                        />
                        <div>
                            Services
                        </div>
                    </div>
                    <div
                        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                        onClick={() => goTo('/bundles')}
                    >
                        <Avatar
                            className={classNames('p-mr-3', { 'current-page': getCurrentPage() === 'bundles' })}
                        >
                            <ImTree className='im-tree-icon' />
                        </Avatar>
                        <div>
                            Bundles
                        </div>
                    </div>
                </div>
            )
        }

        return null;
    }

    const renderObjectStorageMenu = () => {
        if (hasAccessRights('object-storage')) {
            return (
                <div className='item-render'>
                    <hr />
                    <div
                        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                        onClick={() => goTo('/object-storage')}
                    >
                        <Avatar
                            className={classNames('p-mr-3', { 'current-page': getCurrentPage() === 'object-storage' })}
                            icon='pi pi-th-large'
                        />
                        <div>
                            Object Storage
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    const renderDataExplorerMenu = () => {
        if (hasAccessRights('data-explorer')) {
            return (
                <div className='item-render'>
                    <div
                        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                        onClick={() => goTo('/data-explorer')}
                    >
                        <Avatar
                            className={classNames('p-mr-3', { 'current-page': getCurrentPage() === 'data-explorer' })}
                            icon='pi pi-table'
                        />
                        <div>
                            Tables
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    return (
        <div
            className={classNames('sub-menu', { 'p-d-none': pathname === '/' })}
            style={{ width: '244px' }}
        ><h3 className="p-text-capitalize p-text-normal p-mt-0 project-name">
                {decorateAecProjectName()}
            </h3>
            <div className='p-d-flex p-flex-column items-container'>


                <div
                    className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                    onClick={() => goTo('/')}
                >

                    <Avatar
                        className={classNames('p-mr-3', { 'current-page': getCurrentPage() === 'dashboard' })}
                        icon='pi pi-th-large'
                    />
                    <div>
                        Dashboard
                    </div>
                </div>


                {renderFlowsMenu()}

                <hr />

                {renderTransformMenu()}

                {renderTestMenu()}

                {renderDocsMenu()}

                {renderEventSchedulerMenu()}

                {renderObjectStorageMenu()}

                {renderDataExplorerMenu()}

                <hr />
                <div
                    className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                    onClick={() => { window.open(aecProject.gitURL, '_blank').focus(); }}
                >
                    <Avatar
                        className='p-mr-3'
                    >
                        <DiGit className='di-git-icon' />
                    </Avatar>
                    <div>
                        Git
                    </div>
                </div>

                <hr />

                {getAuthtokenExternalApps().map((extApp, index) =>
                    <div key={`${extApp.name}-${index}`}>
                        <SubMenuExternalApp
                            className='item-container p-mb-2'
                            externalApp={extApp} />
                    </div>
                )}

                <hr />

                <div
                    className='p-d-inline-flex p-ai-center item-container'
                    onClick={() => goToGettingStarted()}
                >
                    <Avatar
                        className='p-mr-3'
                        icon='pi pi-question'
                    />
                    <div>
                        Guide
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SubMenu;

/*
 <GitSync
                CustomButton={() => (
                    <div className='p-d-inline-flex p-ai-center'>
                        <Button
                            icon="pi pi-globe"
                            className='p-button p-mr-3 git-sync-button'
                            loading={gitSyncIsLoading}
                        />
                        <div>
                            Sync the project
                        </div>
                    </div>
                )}
                appendTo='self'
                className='item-container'
                gitSyncCtlr={(commitMessage) => syncAecProjectCtlr(aecProject.name, commitMessage)}
            />
*/

/*
    <div
        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
        onClick={() => { window.open('https://apps.datatask.io/apps/jupyterlab-affinitechv2/lab?', '_blank').focus(); }}
    >
        <Avatar
            className='p-mr-3'
        >
            <SiJupyter className='si-jupyter-icon' />
        </Avatar>
        <div>
            Jupyter
        </div>
    </div>



    <div
        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
        onClick={() => { window.open('https://apps.datatask.io/apps/mlflowtracksqlite/#/', '_blank').focus(); }}
    >
        <Avatar
            className='p-mr-3'
        >
            <BiBrain className='bi-brain-icon' />
        </Avatar>
        <div>
            MLflow
        </div>
    </div>

    <div
        className='p-d-inline-flex p-ai-center p-mb-2 item-container'
        onClick={() => { window.open('https://apps.datatask.io/apps/dtale/', '_blank').focus(); }}
    >
        <Avatar
            className='p-mr-3'
        >
            <ImTable className='im-table-icon' />
        </Avatar>
        <div>
            D-Tale
        </div>
    </div>
*/
