//React imports
import React, { useContext } from 'react';
//---

//CSS imports
import './TaskForm.css'
//---

//PrimeReact imports
import { Button } from 'primereact/button';
//---

//Vendors imports
import Form from '@rjsf/core';
//---

//Components imports
import { ObjectFieldTemplate, CustomFieldTemplate, CustomArrayFieldTemplate, CustomWidgets } from '../CustomJsonForm';
import { GlobalAecProjectStateContext } from '../GlobalAecProjectStateProvider';
import { getLastSelectedTaskEngine } from '../../data/AppLocalData';
//---

const uiSchema = {
    "ui:order": [
        "name",
        "description",
        "git-url",
        "git-branch",
        "code-path",
        "engine",
        "env"
    ]
}

const TaskForm = ({ onFormSubmit }) => {
    const { aecProject } = useContext(GlobalAecProjectStateContext);

    const determineLastSelectedEngine = () => {
        let selectedEngine = getLastSelectedTaskEngine();
        if (selectedEngine) {
            return selectedEngine;
        }

        return '';
    }

    const selectedEngine = determineLastSelectedEngine()

    const schema = {
        "type": "object",
        "properties": {
            "name": {
                "title": "Name",
                "name": "name",
                "default": "",
                "type": "string",
                "description": ""
            },
            "description": {
                "title": "Description",
                "name": "description",
                "default": "",
                "type": "string",
                "description": ""
            },
            "git-url": {
                "title": "Git URL",
                "name": "git-url",
                "default": "",
                "type": "string",
                "description": "Link to the git repository"
            },
            "git-branch": {
                "title": "Git branch",
                "name": "git-branch",
                "default": "main",
                "type": "string",
                "description": ""
            },
            "code-path": {
                "title": "Code path",
                "name": "code-path",
                "default": "",
                "type": "string",
                "description": "Path to the directory containing the Dockerfile. Example : If your Dockerfile is located at the path 'my-tasks/task1/Dockerfile' of your git repository, the 'code-path' must be : 'my-tasks/task1'"
            },
            "engine": {
                "title": "Engine",
                "name": "engine",
                "type": "string",
                "description": "",
                "default": selectedEngine,
                "enum": aecProject.eventSchedulerTaskEngines
            },
            "env": {
                "title": "Env",
                "name": "env",
                "type": "array",
                "description": "Environment vars",
                "items": {
                    "type": "object",
                    "required": [
                        "name",
                        "value"
                    ],
                    "properties": {
                        "name": {
                            "type": "string",
                            "title": "Name",
                            "description": ""
                        },
                        "value": {
                            "type": "string",
                            "title": "Value",
                            "description": ""
                        },
                    }
                }
            },
        },
        "required": [
            "name",
            "description",
            "git-url",
            "git-branch",
            "engine"
        ]
    }

    return (
        <Form
            className='p-fluid task-form'
            schema={schema}
            uiSchema={uiSchema}
            autoComplete='on'
            onSubmit={onFormSubmit}
            showErrorList={false}
            ObjectFieldTemplate={ObjectFieldTemplate}
            ArrayFieldTemplate={CustomArrayFieldTemplate}
            FieldTemplate={CustomFieldTemplate}
            widgets={CustomWidgets}
        >
            <Button label='Launch' type='submit' />
        </Form>
    );
};

export default TaskForm;
