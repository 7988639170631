//React imports
import React, { } from 'react';
import { useHistory, Link } from 'react-router-dom';
//---

//CSS imports
import './BundleDetails.css'
//---

//PrimeReact imports
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { Panel } from 'primereact/panel';
import { Divider } from 'primereact/divider';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chip } from 'primereact/chip';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
//---

//Vendors imports
//---

//Components imports
//---

//Utils imports
import { formatFullDate } from '../../utils/Date';
//---

const BundleDetails = ({ bundle, projectName, deleteBundle }) => {
    const history = useHistory();

    const onEdit = () => {
        history.push({
            pathname: '/' + projectName + '/bundle/update/' + bundle['redis-id'],
        })
    }

    const onDelete = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Do you want to delete this bundle ?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => deleteBundle(bundle['redis-id']),
        });
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Link className='resource-link' to={`/${projectName}/artefacts/${rowData.name}`}>
                    {rowData.name}
                </Link>
            </React.Fragment>
        );
    }

    const renderDependencies = (dependency) => {
        if (dependency) {
            if (dependency.type === 'tasks') {
                if (dependency['tasks-dependencies']) {
                    if (dependency['tasks-dependencies'].tasks) {
                        return dependency['tasks-dependencies'].tasks.map((task, i) => (
                            <Chip key={`${task.name}-${i}`} label={task.name} className="p-m-1 custom-chip" />
                        ));
                    }
                }
            } else if (dependency.type === 'files') {
                return null;
            }
        }

        return null;
    }

    const dependenciesBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='p-d-flex p-ai-center p-flex-wrap'>
                    {renderDependencies(rowData.dependency)}
                </div>
            </React.Fragment>
        );
    }

    return (
        <div className='bundle-details p-mb-2'>
            <ConfirmPopup />
            <Fieldset className='p-ml-0 p-mr-0 container' legend={bundle.name}>
                <h3 className='title-color'>Details</h3>
                <p><span className="p-text-bold">Name : </span>{bundle.name}</p>
                <p><span className="p-text-bold">Description : </span>{bundle.description}</p>
                <p><span className="p-text-bold">Last modified : </span>{formatFullDate(bundle['last-modified-time'])}</p>
                <p><span className="p-text-bold">Created : </span>{formatFullDate(bundle['creation-time'])}</p>

                <Divider />

                <Panel className='p-mb-3 panel' header="Bundled artefacts" toggleable>
                    <DataTable value={bundle.artefacts}
                        responsiveLayout="scroll"
                        emptyMessage="No selected artefacts."
                        className="p-datatable-bundle-details p-mt-2"
                    >
                        <Column field="name" body={nameBodyTemplate} columnKey='name' />

                        <Column field="dependencies" header="Dependencies" body={dependenciesBodyTemplate} columnKey='dependencies' />
                    </DataTable>
                </Panel>

                <Divider />

                <Button
                    className='p-mr-2'
                    label='Edit' icon="pi pi-pencil" onClick={onEdit}
                />
                <Button
                    className="p-button-danger p-button-outlined p-mr-2"
                    label='Delete' icon="pi pi-times" onClick={onDelete}
                />
            </Fieldset>
        </div>
    );
};

export default BundleDetails;