//Vendors imports
import axios from 'axios';
import Cookies from 'universal-cookie';
import jwt_decode from "jwt-decode";
//---

//Utils imports
import { log } from '../utils/Log';
//---

const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;

const IS_PROD = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? false : true;

axios.interceptors.request.use(
    function (config) {
        let cookies = new Cookies();
        let authtoken = cookies.get('sda_authtoken')
        if (authtoken) {
            config.headers["Authorization"] = 'Bearer ' + authtoken;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export const processDefaultLogin = (cancelTokenSource, username, password) => {
    let formData = new FormData();
    if (username !== '') {
        formData.append('username', username);
    }

    if (password !== '') {
        formData.append('password', password);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AUTH_API_URL + '/v1/login/default',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const processGoogleLogin = (cancelTokenSource, tokenId) => {
    let formData = new FormData();
    if (tokenId !== '') {
        formData.append('tokenId', tokenId);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AUTH_API_URL + '/v1/login/google',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const checkIfAuthenticated = () => {
    return new Promise((resolve, reject) => {
        try {
            const cookies = new Cookies();
            let authtoken = cookies.get('sda_authtoken')
            if (authtoken) {
                let decoded = jwt_decode(authtoken);
                let now = Date.now()
                let exp = decoded.exp * 1000;

                if (now < exp) {
                    resolve('access granted');
                    return;
                }

                reject('token has expired')
            } else {
                reject('no token')
            }
        } catch (e) {
            reject('cannot get token')
        }
    });
}

export const setAuthtokenCookie = (authtoken) => {
    return new Promise((resolve, reject) => {
        try {
            let decoded = jwt_decode(authtoken);
            let exp = new Date(decoded.exp * 1000);
            let cookies = new Cookies();
            cookies.set('sda_authtoken', authtoken, {
                path: '/',
                expires: exp,
                secure: IS_PROD
            });
            resolve('token successfully set');
        } catch (e) {
            reject('cannot set token');
        }
    });
}

export const removeAuthtokenCookie = () => {
    return new Promise((resolve, reject) => {
        try {
            let cookies = new Cookies();
            cookies.remove('sda_authtoken')
            resolve('token successfully removed');
        } catch (e) {
            reject('cannot remove token');
        }
    });
}

export const isUserAdmin = () => {
    try {
        const cookies = new Cookies();
        let authtoken = cookies.get('sda_authtoken')
        if (authtoken) {
            let decoded = jwt_decode(authtoken);
            if (decoded.data) {
                if (decoded.data['role']) {
                    if (decoded.data['role'] === 'superadmin' || decoded.data['role'] === 'admin') {
                        return true
                    }
                }
            }
        }

        return false;
    } catch (e) {
        return false;
    }
}

export const getAuthtokenExternalApps = () => {
    try {
        const cookies = new Cookies();
        let authtoken = cookies.get('sda_authtoken')
        if (authtoken) {
            let decoded = jwt_decode(authtoken);
            if (decoded.data) {
                if (decoded.data['external-apps']) {
                    return decoded.data['external-apps']
                }
            }
        }

        return [];
    } catch (e) {
        return [];
    }
}

export const hasAccessRights = (key) => {
    let sdaConfiguration = getAuthtokenData('sda-configuration');
    if (sdaConfiguration) {
        if (sdaConfiguration[key]) {
            if (sdaConfiguration[key].active) {
                return true;
            }
        }
    }

    return false;
}

export const hasQueryFullAccessRight = () => {
    let sdaConfiguration = getAuthtokenData('sda-configuration');
    if (sdaConfiguration) {
        if (sdaConfiguration['data-explorer']) {
            if (sdaConfiguration['data-explorer']['query-full-access']) {
                return true;
            }
        }
    }

    return false;
}

export const getEventSchedulerTaskEngines = () => {
    let engines = [];
    let sdaConfiguration = getAuthtokenData('sda-configuration');
    if (sdaConfiguration) {
        if (sdaConfiguration['event-scheduler']) {
            if (sdaConfiguration['event-scheduler'].engines) {
                if (sdaConfiguration['event-scheduler'].engines['datatask']) {
                    if (sdaConfiguration['event-scheduler'].engines['datatask'].active) {
                        engines.push('datatask')
                    }
                }

                if (sdaConfiguration['event-scheduler'].engines['clever-cloud']) {
                    if (sdaConfiguration['event-scheduler'].engines['clever-cloud'].active) {
                        engines.push('clever-cloud')
                    }
                }

                if (sdaConfiguration['event-scheduler'].engines['job-runner']) {
                    if (sdaConfiguration['event-scheduler'].engines['job-runner'].active) {
                        engines.push('job-runner')
                    }
                }
            }
        }
    }

    return engines;
}

export const getEventSchedulerServiceEngines = () => {
    let engines = [];
    let sdaConfiguration = getAuthtokenData('sda-configuration');
    if (sdaConfiguration) {
        if (sdaConfiguration['event-scheduler']) {
            if (sdaConfiguration['event-scheduler'].engines) {
                if (sdaConfiguration['event-scheduler'].engines['clever-cloud']) {
                    if (sdaConfiguration['event-scheduler'].engines['clever-cloud'].active) {
                        engines.push('clever-cloud')
                    }
                }

                if (sdaConfiguration['event-scheduler'].engines['scaleway']) {
                    if (sdaConfiguration['event-scheduler'].engines['scaleway'].active) {
                        engines.push('scaleway')
                    }
                }
            }
        }
    }

    return engines;
}

export const getAuthtokenData = (key) => {
    try {
        const cookies = new Cookies();
        let authtoken = cookies.get('sda_authtoken')
        if (authtoken) {
            let decoded = jwt_decode(authtoken);
            if (decoded.data) {
                return decoded.data[key];
            }
        }

        return '';
    } catch (e) {
        return '';
    }
}

export const getAuthtokenClaim = (key) => {
    try {
        const cookies = new Cookies();
        let authtoken = cookies.get('sda_authtoken')
        if (authtoken) {
            let decoded = jwt_decode(authtoken);
            return decoded[key]
        }

        return '';
    } catch (e) {
        return '';
    }
}