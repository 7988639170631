//React imports
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
//---

//CSS imports
import './ConnectionsInventory.css'
//---

//PrimeReact imports
import { DataView } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
//---

//Vendors imports
//---

//Components imports
//---

//Data requests imports
//---

const AEC_API_URL = process.env.REACT_APP_AEC_API_URL;

const ConnectionsInventory = ({ connections, selectedConnection, onSelectConnection, projectName, displayBtnNewConnection = true }) => {
    const [filteredConnections, setFilteredConnections] = useState([]);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            setFilteredConnections(connections)
        }

        return () => {
            mounted = false;
        };
    }, [connections]);

    const searchConnectionByTitle = (value) => {
        setFilteredConnections(connections.filter(conn =>
            conn.name.toLowerCase().includes(value.toLowerCase()) ||
            conn.description.toLowerCase().includes(value.toLowerCase()) ||
            conn['connection-type'].toLowerCase().includes(value.toLowerCase())
        ))
    }

    const renderListItem = (connection) => {
        return (
            <div className="p-col-12">
                <div
                    className={classNames("connection-list-item p-d-flex p-flex-column",
                        { 'selected-connection': selectedConnection.name === connection.name })}
                    onClick={() => onSelectConnection(connection)}
                >
                    <div className='p-d-flex p-ai-center p-mb-2'>
                        <img src={AEC_API_URL + connection.icon} alt={connection.name} />
                        <div className="connection-name">{connection.name}</div>
                    </div>
                    <div className="connection-list-detail">
                        <div className="connection-description">{connection.description}</div>
                        <i className="pi pi-tag connection-category-icon"></i>
                        <span className="connection-category">{connection['connection-type']}</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='connections-inventory'>
            <div className="table-header p-d-inline-flex p-mb-2">
                <span className="p-input-icon-left header-input">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => searchConnectionByTitle(e.target.value)} placeholder="Search" style={{ width: '100%' }} />
                </span>
                <Link to={`/${projectName}/connection/create`}>
                    <Button
                        className={classNames('p-ml-2 header-button', { 'p-d-none': !displayBtnNewConnection })}
                        label='New' icon="pi pi-plus"
                    />
                </Link>

            </div>
            <DataView
                value={filteredConnections}
                layout='list'
                itemTemplate={renderListItem}
                className='dataview'
            />
        </div>
    );
};

export default ConnectionsInventory;
