//React imports
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
//---

//CSS imports
import './PutConnection.css'
//---

//PrimeReact imports
import { Avatar } from 'primereact/avatar';
import { Steps } from 'primereact/steps';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import ConnectionForm from '../../components/connection/ConnectionForm';
import ConnectionsStore from '../../components/connection/ConnectionsStore';
//---

//Data requests imports
import {
    listAvailableSingerConnections,
    createSingerConnection,
    updateSingerConnection,
    readSingerConnection
} from '../../data/SingerConnectionData';
//---

const PutConnection = ({ projectName, mode }) => {
    const history = useHistory();

    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    const [availableConnections, setAvailableConnections] = useState([])
    const [selectedConnection, setSelectedConnection] = useState(null)
    const [existingConnection, setExistingConnection] = useState(null)

    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const steps = [
        {
            title: 'Start by choosing the type of connection you want to create',
            label: 'Choose a connection type',
            disabled: mode === 'update' ? true : false
        },
        {
            title: 'Fill out the form to create your connection',
            label: 'Configure the connection',
            disabled: activeStepIndex < 1 ? true : false
        }
    ];

    useEffect(() => {
        listAvailableSingerConnectionsCtlr();

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listAvailableSingerConnectionsCtlr = () => {
        listAvailableSingerConnections(cancelTokenSource).then(
            data => {
                if (data.singerConnections) {
                    setAvailableConnections(data.singerConnections);
                    if (mode === 'update') {
                        let _urlSearchParams = new URLSearchParams(history.location.search);
                        let connectionName = _urlSearchParams.get("selected-connection")
                        if (connectionName) {
                            readSingerConnectionCtlr(connectionName, data.singerConnections)
                        }
                    }
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const createSingerConnectionCtlr = (singerConnection) => {
        createSingerConnection(cancelTokenSource, projectName, singerConnection).then(
            data => {
                showNotification('success', 'Success', 'connection successfully created', 6000)
                history.push({
                    pathname: '/' + projectName + "/connections",
                    search: '?selected-connection=' + (data.singerConnection ? data.singerConnection.name : 'undefined')
                })
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const updateSingerConnectionCtlr = (singerConnectionName, singerConnection) => {
        updateSingerConnection(cancelTokenSource, projectName, singerConnectionName, singerConnection).then(
            data => {
                showNotification('success', 'Success', 'connection successfully updated', 6000)
                history.push({
                    pathname: '/' + projectName + "/connections",
                    search: '?selected-connection=' + (data.singerConnection ? data.singerConnection.name : 'undefined')
                })
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }


    const readSingerConnectionCtlr = (singerConnectionName, availableConnections) => {
        readSingerConnection(cancelTokenSource, projectName, singerConnectionName).then(
            data => {
                if (data.singerConnection) {
                    let _selectedConnection = availableConnections.find(conn => conn['connection-type'] === data.singerConnection['connection-type']);
                    if (_selectedConnection) {
                        let _data = data.singerConnection;
                        _data.configuration['connection-name'] = _data.name;
                        _data.configuration['connection-description'] = _data.description;
                        setExistingConnection(_data);
                        setSelectedConnection(_selectedConnection);
                        setActiveStepIndex(1)
                    }
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const onSelectConnection = (connection) => {
        setSelectedConnection(connection);
        setActiveStepIndex(1);
    }

    const onFormSubmit = ({ formData }, e) => {
        let name = formData['connection-name'];
        let description = formData['connection-description'];

        let _formData = Object.assign({}, formData);

        delete _formData['connection-name'];
        delete _formData['connection-description'];

        let singerConnection = {
            'name': name,
            'description': description,
            'connection-type': selectedConnection['connection-type'],
            'icon': selectedConnection['icon'],
            'configuration': _formData
        };

        let _singerConnection = ''

        try {
            _singerConnection = JSON.stringify(singerConnection);
        } catch (_) {
            showNotification('error', 'Error', 'invalid connection', 6000)
            return;
        }

        if (mode === 'create') {
            createSingerConnectionCtlr(_singerConnection);
        } else {
            updateSingerConnectionCtlr(existingConnection.name, _singerConnection)
        }
    }

    const getTitleText = () => {
        if (selectedConnection) {
            return (
                'Fill out the form to create your connection'
            )
        } else {
            return (
                'Start by choosing the type of connection you want to create'
            )
        }
    }

    const renderStep = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <ConnectionsStore
                    availableConnections={availableConnections}
                    onSelectConnection={onSelectConnection}
                />;
            case 1:
                return <ConnectionForm
                    onFormSubmit={onFormSubmit}
                    selectedConnection={selectedConnection}
                    formData={existingConnection ? existingConnection.configuration : {}}
                    mode={mode}
                />;
            default:
                return 'Unknown stepIndex';
        }
    }

    return (
        <div className='put-connection'>
            <Steps model={steps} activeIndex={activeStepIndex} onSelect={(e) => setActiveStepIndex(e.index)} readOnly={false} />
            <div className='p-d-flex p-flex-column p-jc-center p-ai-center'>
                <Avatar
                    icon='pi pi-key'
                    className='xxl-avatar p-m-3'
                />
                <div className='title'>{getTitleText()}</div>
            </div>
            <div className='p-d-flex p-jc-center p-pb-3 container'>
                {renderStep(activeStepIndex)}
            </div>
        </div>
    );
};

export default PutConnection;
