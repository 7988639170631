//React imports
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
//---

//CSS imports
import './Services.css'
//---

//PrimeReact imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import { GlobalAecProjectStateContext } from '../../components/GlobalAecProjectStateProvider';
//---

//Data requests imports
import {
    listServices
} from '../../data/ServiceData';
import {
    setupExternalAppAccessLink
} from '../../data/ExternalAppData';
//---

const Services = ({ projectName }) => {
    const { aecProject } = useContext(GlobalAecProjectStateContext);

    const { showNotification } = useNotification();

    const cancelTokenSource = axios.CancelToken.source();

    const dt = useRef(null);

    const [services, setServices] = useState([])

    const [globalFilter, setGlobalFilter] = useState('');
    const globalFilterInputRef = useRef(null);

    useEffect(() => {
        if (aecProject.name) {
            listServicesCtlr();
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aecProject]);

    const listServicesCtlr = () => {
        listServices(cancelTokenSource, projectName).then(
            data => {
                if (data.services) {
                    setServices(data.services);
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const goToServiceCtlr = (endpoint, useGrpx) => {
        if (!useGrpx) {
            window.open(
                endpoint,
                '_blank'
            ).focus()
        } else {
            setupExternalAppAccessLink(cancelTokenSource, endpoint).then(
                data => {
                    if (data.activationLink) {
                        if (data.activationLink.link) {
                            window.open(
                                data.activationLink.link,
                                '_blank'
                            ).focus()
                        } else {
                            showNotification('error', 'Error', 'the access link is empty', 6000)
                        }
                    }
                },
                errorMessage => {
                    showNotification('error', 'Error', errorMessage, 6000)
                }
            );
        }
    }

    const onGlobalFilterSearch = (globalFilterValue) => {
        setGlobalFilter(globalFilterValue);
        dt.current.filter(globalFilterValue, 'global', 'contains');
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onGlobalFilterSearch(globalFilterInputRef.current.value);
        }
    }

    const reset = () => {
        setGlobalFilter('');
        globalFilterInputRef.current.value = '';
        dt.current.reset();
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Name</span>
                <Link className='resource-link' to={`/${projectName}/services/${rowData['redis-id']}`}>
                    {rowData.name}
                </Link>
            </React.Fragment>
        );
    }

    const descriptionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Description</span>
                <div className="description">{rowData.description}</div>
            </React.Fragment>
        );
    }

    const endpointsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Endpoints</span>
                <div className="endpoints">
                    {renderEndpoints(rowData['service-deployments'], rowData['use-grpx'])}
                </div>
            </React.Fragment>
        );
    }

    const renderEndpoints = (engineServiceDeployment, useGrpx) => {
        let endpoints = []

        if (engineServiceDeployment && engineServiceDeployment['clever-cloud-service-deployment']) {
            if (engineServiceDeployment['clever-cloud-service-deployment'].endpoints) {
                for (let i = 0; i < engineServiceDeployment['clever-cloud-service-deployment'].endpoints.length; i++) {
                    let endpoint = `https://${engineServiceDeployment['clever-cloud-service-deployment'].endpoints[i]}`
                    endpoints.push(
                        <span
                            key={i}
                            className='resource-link'
                            onClick={() => goToServiceCtlr(endpoint, useGrpx)}
                        >
                            {endpoint}
                        </span>
                    )
                }
            }
        }

        if (engineServiceDeployment && engineServiceDeployment['scaleway-service-deployment']) {
            if (engineServiceDeployment['scaleway-service-deployment'].endpoints) {
                for (let i = 0; i < engineServiceDeployment['scaleway-service-deployment'].endpoints.length; i++) {
                    let endpoint = `https://${engineServiceDeployment['scaleway-service-deployment'].endpoints[i]}`
                    endpoints.push(
                        <span
                            key={i}
                            className='resource-link'
                            onClick={() => goToServiceCtlr(endpoint, useGrpx)}
                        >
                            {endpoint}
                        </span>
                    )
                }
            }
        }

        return endpoints
    }

    const header = (
        <div className="table-header">
            <Link to={`/${projectName}/service/create`}>
                <Button label="New" icon="pi pi-plus" />
            </Link>
            <div>
                <span className="p-input-icon-left p-mr-2">
                    <i className="pi pi-search" />
                    <InputText type="search" ref={globalFilterInputRef} placeholder="Global Search" className="searchbox" onKeyDown={handleKeyDown} />
                    <Button type="button" icon="pi pi-search" className="p-button-outlined p-ml-2" onClick={() => onGlobalFilterSearch(globalFilterInputRef.current.value)} />
                </span>
                <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={reset} />
            </div>
        </div>
    );

    return (
        <div className="services">
            <div className="card">
                <DataTable ref={dt} value={services} paginator rows={10}
                    header={header} className="p-datatable-services"
                    selectionMode="single"
                    responsiveLayout="scroll"
                    globalFilter={globalFilter} emptyMessage="No services found.">

                    <Column
                        field="name" header="Name"
                        body={nameBodyTemplate} sortable filter
                        filterPlaceholder="name" filterMatchMode="contains"
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="description" header="Description"
                        body={descriptionBodyTemplate} sortable filter
                        filterPlaceholder="description" filterMatchMode="contains"
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="endpoints" header="Endpoints"
                        body={endpointsBodyTemplate}
                        showFilterMatchModes={false} showFilterMenuOptions={false} />
                </DataTable>
            </div>
        </div>
    );
};

export default Services;
