//React imports
import React, { useState, useEffect } from 'react';
//---

//CSS imports
import './FileSessionDiff.css'
//---

//PrimeReact imports
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';

//---

//Vendors imports
import { diffChars } from 'diff'
//--



const FileSessionDiffDialog = ({
    fileSessionDiff,
}) => {

    const [isOpen, setIsOpen] = useState(false);

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (fileSessionDiff && fileSessionDiff.entityType === 'model') {
                setActiveIndex(0);
                setIsOpen(true);
            } else if (fileSessionDiff && fileSessionDiff.entityType === 'source') {
                setActiveIndex(3);
                setIsOpen(true);
            } else if (fileSessionDiff && fileSessionDiff.entityType === 'test') {
                setActiveIndex(4);
                setIsOpen(true);
            }
        }

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileSessionDiff]);

    const getTitle = (fileSessionDiff) => {
        if (fileSessionDiff.entityType === 'model') {
            return `Changes in model '${fileSessionDiff.originalDbtModel.name}'`
        } else if (fileSessionDiff.entityType === 'source') {
            return `Changes in source '${fileSessionDiff.originalDbtSource.name}'`
        } else if (fileSessionDiff.entityType === 'test') {
            return `Changes in test '${fileSessionDiff.originalDbtTest.name}'`
        }

        return ''
    }

    const renderDiff = (oldContent, newContent) => {
        let _diff = diffChars(oldContent, newContent);

        return (
            <pre className='file-content'>
                <code>
                    {_diff.map((part, index) => {
                        let partStyle = {
                            backgroundColor: part.added ? '#90ee90' : part.removed ? '#fa8072' : '#f1f1f1'
                        };
                        return <span
                            key={index}
                            style={partStyle}
                        >
                            {part.value}
                        </span>
                    })}
                </code>
            </pre>
        )
    }

    return (
        <Dialog
            className='file-session-diff'
            header={getTitle(fileSessionDiff)}
            visible={isOpen}
            maximizable
            modal
            onHide={() => setIsOpen(false)}
        >
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                {
                    fileSessionDiff.entityType === 'model' ?
                        <TabPanel header="SQL">
                            <div className="p-grid">
                                <div className="p-col">
                                    <h4 className='file-version'>Original file</h4>
                                    <pre className='file-content'>
                                        <code>
                                            {fileSessionDiff.originalDbtModel.SQLFileContent}
                                        </code>
                                    </pre>
                                </div>
                                <div className="p-col">
                                    <h4 className='file-version'>Modified file</h4>
                                    <pre className='file-content'>
                                        <code>
                                            {fileSessionDiff.modifiedDbtModel.SQLFileContent}
                                        </code>
                                    </pre>
                                </div>
                                <div className="p-col">
                                    <h4 className='file-version'>Changes</h4>
                                    {renderDiff(fileSessionDiff.originalDbtModel.SQLFileContent, 
                                        fileSessionDiff.modifiedDbtModel.SQLFileContent)}
                                </div>
                            </div>
                        </TabPanel> :
                        null
                }
                {
                    fileSessionDiff.entityType === 'model' ?
                        <TabPanel header="YML">
                            <div className="p-grid">
                                <div className="p-col">
                                    <h4 className='file-version'>Original file</h4>
                                    <pre className='file-content'>
                                        <code>
                                            {fileSessionDiff.originalDbtModel.YMLFileContent}
                                        </code>
                                    </pre>
                                </div>
                                <div className="p-col">
                                    <h4 className='file-version'>Modified file</h4>
                                    <pre className='file-content'>
                                        <code>
                                            {fileSessionDiff.modifiedDbtModel.YMLFileContent}
                                        </code>
                                    </pre>
                                </div>
                                <div className="p-col">
                                    <h4 className='file-version'>Changes</h4>
                                    {renderDiff(fileSessionDiff.originalDbtModel.YMLFileContent, 
                                        fileSessionDiff.modifiedDbtModel.YMLFileContent)}
                                </div>
                            </div>
                        </TabPanel> :
                        null
                }
                {
                    fileSessionDiff.entityType === 'model' ?
                        <TabPanel header="MD">
                            <div className="p-grid">
                                <div className="p-col">
                                    <h4 className='file-version'>Original file</h4>
                                    <pre className='file-content'>
                                        <code>
                                            {fileSessionDiff.originalDbtModel.MDFileContent}
                                        </code>
                                    </pre>
                                </div>
                                <div className="p-col">
                                    <h4 className='file-version'>Modified file</h4>
                                    <pre className='file-content'>
                                        <code>
                                            {fileSessionDiff.modifiedDbtModel.MDFileContent}
                                        </code>
                                    </pre>
                                </div>
                                <div className="p-col">
                                    <h4 className='file-version'>Changes</h4>
                                    {renderDiff(fileSessionDiff.originalDbtModel.MDFileContent, 
                                        fileSessionDiff.modifiedDbtModel.MDFileContent)}
                                </div>
                            </div>
                        </TabPanel> :
                        null
                }
                {
                    fileSessionDiff.entityType === 'source' ?
                        <TabPanel header="YML">
                            <div className="p-grid">
                                <div className="p-col">
                                    <h4 className='file-version'>Original file</h4>
                                    <pre className='file-content'>
                                        <code>
                                            {fileSessionDiff.originalDbtSource.YMLFileContent}
                                        </code>
                                    </pre>
                                </div>
                                <div className="p-col">
                                    <h4 className='file-version'>Modified file</h4>
                                    <pre className='file-content'>
                                        <code>
                                            {fileSessionDiff.modifiedDbtSource.YMLFileContent}
                                        </code>
                                    </pre>
                                </div>
                                <div className="p-col">
                                    <h4 className='file-version'>Changes</h4>
                                    {renderDiff(fileSessionDiff.originalDbtSource.YMLFileContent, 
                                        fileSessionDiff.modifiedDbtSource.YMLFileContent)}
                                </div>
                            </div>
                        </TabPanel> :
                        null
                }
                {
                    fileSessionDiff.entityType === 'test' ?
                        <TabPanel header="SQL">
                            <div className="p-grid">
                                <div className="p-col">
                                    <h4 className='file-version'>Original file</h4>
                                    <pre className='file-content'>
                                        <code>
                                            {fileSessionDiff.originalDbtTest.SQLFileContent}
                                        </code>
                                    </pre>
                                </div>
                                <div className="p-col">
                                    <h4 className='file-version'>Modified file</h4>
                                    <pre className='file-content'>
                                        <code>
                                            {fileSessionDiff.modifiedDbtTest.SQLFileContent}
                                        </code>
                                    </pre>
                                </div>
                                <div className="p-col">
                                    <h4 className='file-version'>Changes</h4>
                                    {renderDiff(fileSessionDiff.originalDbtTest.SQLFileContent, 
                                        fileSessionDiff.modifiedDbtTest.SQLFileContent)}
                                </div>
                            </div>
                        </TabPanel> :
                        null
                }
            </TabView>
        </Dialog>
    );
};


export default FileSessionDiffDialog;