//React imports
import React from 'react';
import { useHistory } from 'react-router-dom';
//---

//CSS imports
import './Login.css'
//---

//PrimeReact imports
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
//---

//Vendors imports
import axios from 'axios';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
//---

//Components imports
import { useNotification } from "../../components/NotificationProvider";
//---


//Data requests imports
import {
    processDefaultLogin,
    processGoogleLogin,
    setAuthtokenCookie
} from '../../data/LoginData';
//---

const CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_ID;

const Login = () => {
    const history = useHistory();
    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    const defaultValues = {
        username: '',
        password: '',
    }

    const { control, formState: { errors }, handleSubmit } = useForm({ defaultValues });

    const processDefaultLoginCtlr = (data) => {
        processDefaultLogin(cancelTokenSource, data.username, data.password).then(
            data => {
                if (data.authtoken) {
                    setAuthtokenCookie(data.authtoken).then(
                        () => {
                            if (history.action === 'POP') {
                                history.replace('/')
                            } else {
                                history.goBack()
                            }
                        },
                        errorMessage => showNotification('error', 'Error', errorMessage, 6000)
                    )
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const googleLoginOnSuccessCtlr = (response) => {
        processGoogleLogin(cancelTokenSource, response.credential).then(
            data => {
                if (data.authtoken) {
                    setAuthtokenCookie(data.authtoken).then(
                        () => {
                            if (history.action === 'POP') {
                                history.replace('/')
                            } else {
                                history.goBack()
                            }
                        },
                        errorMessage => showNotification('error', 'Error', errorMessage, 6000)
                    )
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className='p-error'>{errors[name].message}</small>
    };

    const googleLoginOnFailureCtlr = (response) => {
        if (response.error !== 'popup_closed_by_user') {
            showNotification('error', 'Error', response.error, 6000)
        }
    }

    return (
        <div className='p-d-flex p-jc-center p-ai-center'>
            <div className='form-login'>
                <div className='card p-shadow-1 p-p-4'>
                    <h4 className='p-text-center'>Smart Data Analytics</h4>
                    <form onSubmit={handleSubmit(processDefaultLoginCtlr)} className='p-fluid' autoComplete='on'>
                        <div className='p-field'>
                            <span className='p-float-label'>
                                <Controller name='username' control={control} rules={{ required: 'Username is required.' }} render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                                <label htmlFor='username' className={classNames({ 'p-error': errors.username })}>Username *</label>
                            </span>
                            {getFormErrorMessage('username')}
                        </div>
                        <div className='p-field'>
                            <span className='p-float-label'>
                                <Controller name='password' control={control} rules={{ required: 'Password is required.' }} render={({ field, fieldState }) => (
                                    <Password id={field.name} {...field} toggleMask className={classNames({ 'p-invalid': fieldState.invalid })} feedback={false} />
                                )} />
                                <label htmlFor='password' className={classNames({ 'p-error': errors.password })}>Password *</label>
                            </span>
                            {getFormErrorMessage('password')}
                        </div>

                        <Button type='submit' label='Login' className='p-mt-2' style={{ backgroundColor: '#2196F3' }} />
                    </form>

                    <div className='p-d-flex p-jc-center p-mt-2'>
                        <GoogleOAuthProvider clientId={CLIENT_ID}>
                            <GoogleLogin
                                onSuccess={googleLoginOnSuccessCtlr}
                                onError={googleLoginOnFailureCtlr}
                            />
                        </GoogleOAuthProvider>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
