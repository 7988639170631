//React imports
import React, { useState, useEffect, useRef } from 'react';
//---

//CSS imports
import './AdminUser.css'
//---

//PrimeReact imports
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { Password } from 'primereact/password';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { FilterService } from 'primereact/api';
import { Chip } from 'primereact/chip';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import AdminUserAccessTokenFormDialog from '../../components/admin/AdminUserAccessTokenFormDialog';
//---

//Data requests imports
import {
    createUser,
    listUsers,
    updateUser,
    deleteUser,
    customPluginsFilterFunction,
    customAccessFilterFunction,
    customACLFilterFunction,
    revokeUserAccessToken
} from '../../data/AdminData';
import {
    defaultUser,
    defaultUniqueAccess,
    defaultUniqueACL,
} from '../../data/DefaultStates';
//---

const AdminUser = () => {
    const { showNotification } = useNotification();

    const cancelTokenSource = axios.CancelToken.source();

    const dt = useRef(null);

    const [users, setUsers] = useState([]);
    const [userDialog, setUserDialog] = useState(false);
    const [userDialogMode, setUserDialogMode] = useState('create');
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);

    const defaultValues = {
        email: '',
        password: '',
        role: 'user',
        externalApps: '[]',
        sdaConfiguration: '{}',
    }

    const { control, formState: { errors }, handleSubmit, setValue, setError, reset } = useForm({ defaultValues });

    const [globalFilter, setGlobalFilter] = useState('');
    const globalFilterInputRef = useRef(null);

    const [selectedRoles, setSelectedRoles] = useState(null);
    const [uniqueRoles, setUniqueRoles] = useState([]);

    const [selectedExternalApps, setSelectedExternalApps] = useState(null);
    const [uniqueExternalApps, setUniqueExternalApps] = useState([]);

    const [selectedAccess, setSelectedAccess] = useState(null);

    const [selectedACL, setSelectedACL] = useState(null);

    const [userConcernedByAccessToken, setUserConcernedByAccessToken] = useState(null);
    const [accessTokenDialog, setAccessTokenDialog] = useState(false);

    const [accessTokenToRevoke, setAccessTokenToRevoke] = useState(null);
    const [revokeAccessTokenDialog, setRevokeAccessTokenDialog] = useState(false);

    useEffect(() => {
        listUsersCtlr();

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listUsersCtlr = () => {
        listUsers(cancelTokenSource).then(
            data => {
                if (data.users) {
                    //We have to separate the "sda-configuration" on two different fields, 
                    //because the DataTable does not support to have two different customs filters on two different columns, 
                    //but on the same "field"
                    for (let i = 0; i < data.users.length; i++) {
                        data.users[i]['access'] = data.users[i]['sda-configuration'];
                        data.users[i]['acl'] = data.users[i]['sda-configuration'];
                    }

                    setUsers(data.users);
                    createUniqueRoles(data.users);
                    createUniqueExternalApps(data.users);
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const createUserCtlr = (user) => {
        createUser(cancelTokenSource, user).then(
            data => {
                if (data.user) {
                    //See comment above for custom filter support
                    data.user['access'] = data.user['sda-configuration']
                    data.user['acl'] = data.user['sda-configuration']

                    let _users = [...users];
                    _users.push(data.user);
                    setUsers(_users);
                    setUserDialog(false);
                    showNotification('success', 'Success', 'user successfully created', 6000);
                    reset();
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const updateUserCtlr = (user) => {
        updateUser(cancelTokenSource, user).then(
            data => {
                if (data.user) {
                    //See comment above for custom filter support
                    data.user['access'] = data.user['sda-configuration']
                    data.user['acl'] = data.user['sda-configuration']

                    let _users = [...users];
                    let index = findIndexByEmail(data.user.email)
                    if (index >= 0) {
                        _users[index] = data.user;
                        setUsers(_users);

                    } else {
                        listUsersCtlr()
                    }
                    setUserDialog(false);
                    showNotification('success', 'Success', 'user successfully updated', 6000);
                    reset();
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const deleteUserCtlr = () => {
        deleteUser(cancelTokenSource, userToDelete).then(
            () => {
                let _users = users.filter(val => val.email !== userToDelete.email);
                setUsers(_users);
                setDeleteUserDialog(false);
                setUserToDelete(null);
                showNotification('success', 'Success', 'user successfully deleted', 6000);
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const onUserAccessTokenCreated = (newAccessToken) => {
        if (newAccessToken) {
            let _users = [...users];
            let index = findIndexByEmail(newAccessToken.email)
            if (index >= 0) {
                _users[index]['access-tokens'].push(newAccessToken);
                setUsers(_users);
            } else {
                listUsersCtlr()
            }
        }
    }

    const revokeUserAccessTokenCtlr = () => {
        revokeUserAccessToken(cancelTokenSource, accessTokenToRevoke).then(
            () => {
                let _users = [...users];
                let index = findIndexByEmail(accessTokenToRevoke.email)
                if (index >= 0) {
                    _users[index]['access-tokens'] = _users[index]['access-tokens'].filter(
                        val => val['token_id'] !== accessTokenToRevoke['token_id']);
                    setUsers(_users);
                } else {
                    listUsersCtlr()
                }
                setRevokeAccessTokenDialog(false);
                setAccessTokenToRevoke(null);
                showNotification('success', 'Success', 'access token successfully revoked', 6000);
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const createUniqueRoles = (users) => {
        let uniqueRoles = [...new Map(users.map(item =>
            [item.role,
            { 'role': item.role }])).values()]
        setUniqueRoles(uniqueRoles)
    }

    const onRolesChange = (e) => {
        dt.current.filter(e.value, 'role', 'in');
        setSelectedRoles(e.value);
    }

    const createUniqueExternalApps = (users) => {
        let eaMap = new Map()
        users.map(item => {
            if (item['external-apps']) {
                item['external-apps'].map(ea =>
                    eaMap.set(ea.name, { 'name': ea.name })
                )
            }

            return null;
        })
        let uniqueExternalApps = [...eaMap.values()];
        setUniqueExternalApps(uniqueExternalApps);
    }

    const formatObjectToJson = (object) => {
        try {
            let json = JSON.stringify(object, null, 2)
            return json
        } catch (_) {
            return ''
        }
    }

    const formatJsonToObject = (json) => {
        try {
            let object = JSON.parse(json)
            return object
        } catch (_) {
            return null
        }
    }

    const findIndexByEmail = (email) => {
        let index = -1;
        for (let i = 0; i < users.length; i++) {
            if (users[i].email === email) {
                index = i;
                break;
            }
        }

        return index;
    }

    const onGlobalFilterSearch = (globalFilterValue) => {
        setGlobalFilter(globalFilterValue);
        dt.current.filter(globalFilterValue, 'global', 'contains');
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onGlobalFilterSearch(globalFilterInputRef.current.value);
        }
    }

    const dtReset = () => {
        setSelectedRoles(null);
        setSelectedExternalApps(null);
        setSelectedAccess(null);
        setSelectedACL(null);
        setGlobalFilter('');
        globalFilterInputRef.current.value = '';
        dt.current.reset();
    }

    const openNew = () => {
        setValue('email', defaultUser.email);
        setValue('role', defaultUser.role);
        setValue('externalApps', formatObjectToJson(defaultUser['external-apps']));
        setValue('sdaConfiguration', formatObjectToJson(defaultUser['sda-configuration']));
        setUserDialogMode('create');
        setUserDialog(true);
    }

    const hideDialog = () => {
        setUserDialog(false);
    }

    const hideAccessTokenDialog = () => {
        setAccessTokenDialog(false);
    }

    const hideDeleteUserDialog = () => {
        setDeleteUserDialog(false);
    }

    const hideRevokeAccessTokenDialog = () => {
        setRevokeAccessTokenDialog(false);
        setAccessTokenToRevoke(null);
    }

    const saveUser = (formData) => {
        let externalApps = formatJsonToObject(formData.externalApps)
        if (!externalApps) {
            setError('externalApps', { message: 'invalide json format' });
            return;
        }

        let sdaConfiguration = formatJsonToObject(formData.sdaConfiguration)
        if (!sdaConfiguration) {
            setError('sdaConfiguration', { message: 'invalide json format' });
            return;
        }

        let user = {
            'email': formData.email,
            'password': formData.password,
            'role': formData.role,
            'external-apps': externalApps,
            'sda-configuration': sdaConfiguration
        }

        if (user.email.trim()) {
            if (userDialogMode === 'create') {
                //Create case
                if (findIndexByEmail(user.email) !== -1) {
                    showNotification('error', 'Error', 'email already exists', 6000);
                }
                createUserCtlr(user)
            } else {
                //Update case
                updateUserCtlr(user)
            }
        }
    }

    const editUser = (user) => {
        setValue('email', user.email);
        setValue('role', user.role);
        setValue('externalApps', formatObjectToJson(user['external-apps']));
        setValue('sdaConfiguration', formatObjectToJson(user['sda-configuration']));
        setUserDialogMode('update');
        setUserDialog(true);
    }

    const confirmDeleteUser = (user) => {
        setUserToDelete(user);
        setDeleteUserDialog(true)
    }

    const createUserAccessToken = (user) => {
        setUserConcernedByAccessToken(user);
        setAccessTokenDialog(true);
    }

    const confirmRevokeAccessToken = (accessToken) => {
        setAccessTokenToRevoke(accessToken);
        setRevokeAccessTokenDialog(true)
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className='p-error'>{errors[name].message}</small>
    };

    const renderPlugins = (externalApps) => {
        if (externalApps) {
            return externalApps.map((app, i) => (
                <Chip key={`${app.name}-${i}`} label={app.name} className="p-m-1 plugin-chip" />
            ));
        }

        return null;
    }

    const renderConfiguration = (sdaConfiguration) => {
        let chips = []

        if (sdaConfiguration) {
            //Check if full access to areas
            if (sdaConfiguration['audit-trail'] && sdaConfiguration['audit-trail'].active &&
                sdaConfiguration['data-explorer'] && sdaConfiguration['data-explorer'].active &&
                sdaConfiguration['event-scheduler'] && sdaConfiguration['event-scheduler'].active &&
                sdaConfiguration['flows'] && sdaConfiguration['flows'].active &&
                sdaConfiguration['object-storage'] && sdaConfiguration['object-storage'].active &&
                sdaConfiguration['transform'] && sdaConfiguration['transform'].active &&
                sdaConfiguration['docs'] && sdaConfiguration['docs'].active) {
                chips.push(<Chip key='full-access' label='all' className="p-m-1 full-access-chip" />)
            } else {
                Object.keys(sdaConfiguration).map((key, i) => {
                    if (sdaConfiguration[key].active) {
                        chips.push(<Chip key={`${key}-${i}`} label={key} className={`p-m-1 ${key}-access-chip`} />)
                    }

                    return null;
                })
            }
        }

        return chips;
    }

    const renderACL = (sdaConfiguration) => {
        let chips = []

        if (sdaConfiguration) {
            if (sdaConfiguration['data-explorer'] && sdaConfiguration['data-explorer'].acl &&
                Object.keys(sdaConfiguration['data-explorer'].acl).length > 0) {
                chips.push(<Chip key='data-explorer-acl' label='data-explorer:restricted'
                    className="p-m-1 data-explorer-restricted-access-chip" />)
            } else if (sdaConfiguration['data-explorer'] && sdaConfiguration['data-explorer'].active) {
                chips.push(<Chip key='data-explorer-acl' label='data-explorer:all'
                    className="p-m-1 data-explorer-all-access-chip" />)
            }

            if (sdaConfiguration['object-storage'] && sdaConfiguration['object-storage'].acl &&
                Object.keys(sdaConfiguration['object-storage'].acl).length > 0) {
                chips.push(<Chip key='object-storage-acl' label='object-storage:restricted'
                    className="p-m-1 object-storage-restricted-access-chip" />)
            } else if (sdaConfiguration['object-storage'] && sdaConfiguration['object-storage'].active) {
                chips.push(<Chip key='object-storage-acl' label='object-storage:all'
                    className="p-m-1 object-storage-all-access-chip" />)
            }
        }

        return chips;
    }

    const renderAccessTokens = (rowData) => {
        let chips = []

        if (rowData['access-tokens']) {
            rowData['access-tokens'].map((at, i) => {
                chips.push(
                    <Chip
                        key={`${at['token_id']}-${i}`}
                        className="p-m-1 access-token-chip"
                        template={
                            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                <span className="p-chip-text">
                                    {at['token_name']}
                                </span>
                                <span tabIndex="0"
                                    className="p-chip-remove-icon pi pi-times-circle"
                                    onClick={() => {
                                        confirmRevokeAccessToken(at);
                                    }}></span>
                            </div>
                        }
                    />
                )

                return null;
            });
        }

        chips.push(
            <Button
                key='new-at'
                icon="pi pi pi-plus-circle"
                className="p-button-outlined p-button p-m-1 button-new-access-token"
                label='new'
                onClick={() => {
                    createUserAccessToken({ 'email': rowData.email, 'sda-url': rowData['sda-url'] })
                }}
            />
        )

        return chips;
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Email</span>
                <div className="description">{rowData.email}</div>
            </React.Fragment>
        );
    }

    const roleBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Role</span>
                <div className="description">{rowData.role}</div>
            </React.Fragment>
        );
    }

    const pluginsBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Plugins</span>
                <div className='p-d-flex p-ai-center p-flex-wrap'>
                    {renderPlugins(rowData['external-apps'])}
                </div>
            </React.Fragment>
        );
    }

    const configurationBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Access</span>
                <div className='p-d-flex p-ai-center p-flex-wrap'>
                    {renderConfiguration(rowData['sda-configuration'])}
                </div>
            </React.Fragment>
        );
    }

    const aclBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">ACL</span>
                <div className='p-d-flex p-ai-center p-flex-wrap'>
                    {renderACL(rowData['sda-configuration'])}
                </div>
            </React.Fragment>
        );
    }

    const accessTokensBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Access Tokens</span>
                <div className='p-d-flex p-ai-center p-flex-wrap'>
                    {renderAccessTokens(rowData)}
                </div>
            </React.Fragment>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-outlined p-button p-mr-2" onClick={() => editUser(rowData)} />
                <Button icon="pi pi-trash" className="p-button-outlined p-button-danger" onClick={() => confirmDeleteUser(rowData)} />
            </React.Fragment>
        );
    }

    const roleItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <span>{option.role}</span>
            </div>
        );
    }

    const roleFilterTemplate = <MultiSelect
        value={selectedRoles} options={uniqueRoles}
        itemTemplate={roleItemTemplate} onChange={onRolesChange}
        optionLabel="role" optionValue="role" placeholder="All" className="p-column-filter"
    />

    const pluginsItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <span>{option.name}</span>
            </div>
        );
    }

    const pluginsFilterTemplate = (options) => <MultiSelect
        value={selectedExternalApps} options={uniqueExternalApps}
        itemTemplate={pluginsItemTemplate} onChange={(e) => onExternalAppsChange(e, options)}
        optionLabel="name" optionValue="name" placeholder="All" className="p-column-filter"
    />

    const accessItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <span>{option.name}</span>
            </div>
        );
    }

    const accessFilterTemplate = (options) => <MultiSelect
        value={selectedAccess} options={defaultUniqueAccess}
        itemTemplate={accessItemTemplate} onChange={(e) => onAccessChange(e, options)}
        optionLabel="name" optionValue="name" placeholder="All" className="p-column-filter"
    />

    const aclItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <span>{option.name}</span>
            </div>
        );
    }

    const aclFilterTemplate = (options) => <MultiSelect
        value={selectedACL} options={defaultUniqueACL}
        itemTemplate={aclItemTemplate} onChange={(e) => onACLChange(e, options)}
        optionLabel="name" optionValue="name" placeholder="All" className="p-column-filter"
    />

    const header = (
        <div className="table-header">
            <Button label="New" icon="pi pi-plus" onClick={openNew} />
            <div>
                <span className="p-input-icon-left p-mr-2">
                    <i className="pi pi-search" />
                    <InputText type="search" ref={globalFilterInputRef} placeholder="Global Search" className="searchbox" onKeyDown={handleKeyDown} />
                    <Button type="button" icon="pi pi-search" className="p-button-outlined p-ml-2" onClick={() => onGlobalFilterSearch(globalFilterInputRef.current.value)} />
                </span>
                <Button type="button" label="Clear" className="p-button-outlined" icon="pi pi-filter-slash" onClick={dtReset} />
            </div>
        </div>
    );


    const userDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => {
                hideDialog();
                reset();
            }} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={handleSubmit(saveUser)} />
        </React.Fragment>
    );

    const deleteUserDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUserDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteUserCtlr} />
        </React.Fragment>
    );

    const revokeAccessTokenDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideRevokeAccessTokenDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={revokeUserAccessTokenCtlr} />
        </React.Fragment>
    );

    const onExternalAppsChange = (e, options) => {
        options.filterCallback(e.value);
        setSelectedExternalApps(e.value);
    }

    const onAccessChange = (e, options) => {
        options.filterCallback(e.value);
        setSelectedAccess(e.value);
    }

    const onACLChange = (e, options) => {
        options.filterCallback(e.value);
        setSelectedACL(e.value);
    }

    FilterService.register('pluginsFilter', customPluginsFilterFunction);
    FilterService.register('accessFilter', customAccessFilterFunction);
    FilterService.register('aclFilter', customACLFilterFunction);

    return (
        <div className="admin-user">
            <div className="card">
                <DataTable ref={dt} value={users} paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                    header={header} className="p-datatable-users"
                    selectionMode="single"
                    responsiveLayout="scroll" showGridlines
                    globalFilter={globalFilter} emptyMessage="No users found."
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users">

                    <Column field="email" header="Email"
                        body={emailBodyTemplate} sortable filter
                        filterPlaceholder="email" filterMatchMode="contains"
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="role" header="Role"
                        body={roleBodyTemplate} sortable filter
                        filterPlaceholder="role" filterElement={roleFilterTemplate}
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="external-apps" header="Plugins"
                        body={pluginsBodyTemplate} filter
                        filterPlaceholder="plugins" filterElement={pluginsFilterTemplate} filterMatchMode="pluginsFilter"
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="access" header="Access"
                        body={configurationBodyTemplate} filter
                        filterPlaceholder="access" filterElement={accessFilterTemplate} filterMatchMode="accessFilter"
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="acl" header="ACL"
                        body={aclBodyTemplate} filter
                        filterPlaceholder="acl" filterElement={aclFilterTemplate} filterMatchMode="aclFilter"
                        showFilterMatchModes={false} showFilterMenuOptions={false} />

                    <Column field="access-tokens" header="Access Tokens"
                        body={accessTokensBodyTemplate} />

                    <Column body={actionBodyTemplate} exportable={false}
                        style={{ width: '8rem', textAlign: 'center' }} />
                </DataTable>
            </div>

            <Dialog visible={userDialog} style={{ width: '800px', maxWidth: '96%' }} header="User data" modal className="p-fluid" footer={userDialogFooter}
                onHide={() => {
                    hideDialog();
                    reset();
                }}>
                <form onSubmit={handleSubmit(saveUser)} className='p-fluid' autoComplete='off'>
                    <div className='p-field'>
                        <label htmlFor='email' className={classNames('p-d-block', { 'p-error': errors.email })}>Email *</label>
                        <Controller name='email' control={control} rules={{ required: 'Email is required.' }} render={({ field, fieldState }) => (
                            <InputText id={field.name} {...field}
                                autoFocus={userDialogMode === 'create'} disabled={userDialogMode === 'update'}
                                className={classNames('p-d-block dialog-input', { 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('email')}
                    </div>

                    <div className='p-field'>
                        <label htmlFor='password' className={classNames('p-d-block', { 'p-error': errors.password })}>
                            Password {userDialogMode === 'create' ? "*" : null}</label>
                        <Controller name='password' control={control}
                            rules={userDialogMode === 'create' ? { required: 'Password is required.' } : { required: false }} render={({ field, fieldState }) => (
                                <Password id={field.name} {...field}
                                    toggleMask
                                    className={classNames('p-d-block dialog-input', { 'p-invalid': fieldState.invalid })} />
                            )} />
                        {userDialogMode === 'update' ? <small id="password-help" className="p-d-block">Leave empty if unchanged</small> : null}
                        {getFormErrorMessage('password')}
                    </div>

                    <div className='p-field'>
                        <label htmlFor='role' className={classNames('p-d-block', { 'p-error': errors.role })}>Role *</label>
                        <Controller name='role' control={control} rules={{ required: 'Role is required.' }} render={({ field: { onChange, value } }) => (
                            <div className="p-formgrid p-grid">
                                <div className="p-field-radiobutton p-col-4">
                                    <RadioButton required inputId="roleUser" name="role" value="user"
                                        onChange={onChange} checked={value === 'user'} />
                                    <label htmlFor="roleUser">user</label>
                                </div>
                                <div className="p-field-radiobutton p-col-4">
                                    <RadioButton inputId="userAdmin" name="role" value="admin"
                                        onChange={onChange} checked={value === 'admin'} />
                                    <label htmlFor="userAdmin">admin</label>
                                </div>
                                <div className="p-field-radiobutton p-col-4">
                                    <RadioButton inputId="userSuperadmin" name="role" value="superadmin"
                                        onChange={onChange} checked={value === 'superadmin'} />
                                    <label htmlFor="userSuperadmin">superadmin</label>
                                </div>
                            </div>
                        )} />
                        {getFormErrorMessage('role')}
                    </div>

                    <div className='p-field'>
                        <label htmlFor='externalApps' className={classNames('p-d-block', { 'p-error': errors.externalApps })}>Plugins *</label>
                        <Controller name='externalApps' control={control} rules={{ required: 'Plugins is required.' }} render={({ field, fieldState }) => (
                            <InputTextarea id={field.name} {...field} className={classNames('dialog-input', { 'p-invalid': fieldState.invalid })}
                                rows={5} autoResize
                            />
                        )} />
                        {getFormErrorMessage('externalApps')}
                    </div>

                    <div className='p-field'>
                        <label htmlFor='sdaConfiguration' className={classNames('p-d-block', { 'p-error': errors.sdaConfiguration })}>Configuration *</label>
                        <Controller name='sdaConfiguration' control={control} rules={{ required: 'Configuration is required.' }} render={({ field, fieldState }) => (
                            <InputTextarea id={field.name} {...field} className={classNames('dialog-input', { 'p-invalid': fieldState.invalid })}
                                rows={5} autoResize
                            />
                        )} />
                        {getFormErrorMessage('sdaConfiguration')}
                    </div>
                </form>
            </Dialog>

            <AdminUserAccessTokenFormDialog
                user={userConcernedByAccessToken}
                isOpen={accessTokenDialog}
                hide={hideAccessTokenDialog}
                onUserAccessTokenCreated={onUserAccessTokenCreated}
            />

            <Dialog visible={deleteUserDialog} style={{ width: '400px' }} header="Confirm"
                modal footer={deleteUserDialogFooter} onHide={hideDeleteUserDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {userToDelete && <span>Are you sure you want to delete<br /><b>{userToDelete.email}</b> ?</span>}
                </div>
            </Dialog>

            <Dialog visible={revokeAccessTokenDialog} style={{ width: '400px' }} header="Confirm"
                modal footer={revokeAccessTokenDialogFooter} onHide={hideRevokeAccessTokenDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {accessTokenToRevoke && <span>Are you sure you want to revoke<br /><b>{accessTokenToRevoke['token_name']}</b> ?</span>}
                </div>
            </Dialog>
        </div>
    );
};

export default AdminUser;

