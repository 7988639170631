//React imports
import React from 'react';
//---

//CSS imports
import './ArtefactForm.css'
//---

//PrimeReact imports
import { Button } from 'primereact/button';
//---

//Vendors imports
import Form from '@rjsf/core';
//---

//Components imports
import { ObjectFieldTemplate, CustomFieldTemplate, CustomArrayFieldTemplate, CustomWidgets } from '../CustomJsonForm';
//---

const schema = {
    "type": "object",
    "properties": {
        "name": {
            "title": "Name",
            "name": "name",
            "default": "",
            "type": "string",
            "description": ""
        },
        "description": {
            "title": "Description",
            "name": "description",
            "default": "",
            "type": "string",
            "description": ""
        },
        "image": {
            "title": "Image",
            "name": "image",
            "default": "",
            "type": "string",
            "description": "Source image from which the artefact will be created. If you don't have an image, use the 'git-url', 'git-branch' and 'code-path' fields to specify the location of your Dockerfile"
        },
        "git-url": {
            "title": "Git URL",
            "name": "git-url",
            "default": "",
            "type": "string",
            "description": "If no source image use a link to the repository with the code to create the artifact"
        },
        "git-branch": {
            "title": "Git branch",
            "name": "git-branch",
            "default": "main",
            "type": "string",
            "description": ""
        },
        "code-path": {
            "title": "Code path",
            "name": "code-path",
            "default": "",
            "type": "string",
            "description": "Path to the directory containing the Dockerfile. Example : If your Dockerfile is located at the path 'my-artefacts/artefact1/Dockerfile' of your git repository, the 'code-path' must be : 'my-artefacts/artefact1'"
        },
        "env": {
            "title": "Env",
            "name": "env",
            "type": "array",
            "description": "Environment vars",
            "items": {
                "type": "object",
                "required": [
                    "name",
                    "value"
                ],
                "properties": {
                    "name": {
                        "type": "string",
                        "title": "Name",
                        "description": ""
                    },
                    "value": {
                        "type": "string",
                        "title": "Value",
                        "description": ""
                    },
                }
            }
        },
    },
    "required": [
        "name",
        "description"
    ]
}

const uiSchema = {
    "ui:order": [
        "name",
        "description",
        "image",
        "git-url",
        "git-branch",
        "code-path",
        "env"
    ]
}

const ArtefactForm = ({ onFormSubmit, formData, mode = 'create' }) => {
    return (
        <Form
            className='p-fluid artefact-form'
            schema={schema}
            uiSchema={uiSchema}
            autoComplete='on'
            onSubmit={onFormSubmit}
            showErrorList={false}
            ObjectFieldTemplate={ObjectFieldTemplate}
            ArrayFieldTemplate={CustomArrayFieldTemplate}
            FieldTemplate={CustomFieldTemplate}
            widgets={CustomWidgets}
            formData={formData}
        >
            <Button label={mode === 'create' ? 'Create' : 'Update'} type='submit' />
        </Form>
    );
};

export default ArtefactForm;
