//React imports
import React from 'react';

//---

//CSS imports
import './GettingStarted.css'
//---

//PrimeReact imports

//---

//Vendors imports

//---

//Data requests imports
//---

const RELEASE = process.env.REACT_APP_RELEASE;

const GettingStarted = () => {
    return (
        <div className='release-container'>
            <div>release {RELEASE}</div>
        </div>
    );
};

export default GettingStarted;
