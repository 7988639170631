//React imports
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
//---

//CSS imports
import './BundleRunsTable.css'
//---

//PrimeReact imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';
//---

//Vendors imports
import cronstrue from 'cronstrue';
//---

//Utils imports
//---

//Data requests imports
//---

const BundleRunsTable = ({
    bundle,
    projectName,
    bundleStatuses,
    runBundle,
    runBundleLoading,
    applySchedule,
    scheduleBundleLoading,
    selectedEngine,
    availableEngines,
    setSelectedEngine,
    onClickRefresh,
    refreshIsLoading
}) => {
    const dt = useRef(null);
    const op = useRef(null);

    const [globalFilter, setGlobalFilter] = useState('');
    const globalFilterInputRef = useRef(null);

    const [cronExpression, setCronExpression] = useState('');

    const onGlobalFilterSearch = (globalFilterValue) => {
        setGlobalFilter(globalFilterValue);
        dt.current.filter(globalFilterValue, 'global', 'contains');
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onGlobalFilterSearch(globalFilterInputRef.current.value);
        }
    }

    const reset = () => {
        setGlobalFilter('');
        globalFilterInputRef.current.value = '';
        dt.current.reset();
    }

    const idBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Link className='resource-link' to={`/${projectName}/bundle/status/${rowData['redis-id']}`}>
                    {rowData.id}
                </Link>
            </React.Fragment>
        );
    }

    const startTimeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div>{rowData.customStartTime}</div>
            </React.Fragment>
        );
    }

    const completionTimeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div>{rowData.customCompletionTime}</div>
            </React.Fragment>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div>{rowData.status}</div>
            </React.Fragment>
        );
    }

    const stepBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div>{rowData.step}</div>
            </React.Fragment>
        );
    }

    const header = (
        <div className="table-header">
            <div>
                <span className="p-input-icon-left p-mr-2">
                    <i className="pi pi-search" />
                    <InputText type="search" ref={globalFilterInputRef} placeholder="Global Search" className="searchbox" onKeyDown={handleKeyDown} />
                    <Button type="button" icon="pi pi-search" className="p-button-outlined p-ml-2" onClick={() => onGlobalFilterSearch(globalFilterInputRef.current.value)} />
                </span>
                <Button type="button" label="Clear" className="p-button-outlined p-mr-2" icon="pi pi-filter-slash" onClick={reset} />
                <Button type="button" label="Refresh" className="p-button-outlined" icon="pi pi-replay" onClick={onClickRefresh} loading={refreshIsLoading} />
            </div>
        </div>
    );

    const applyTrigger = (cronExpression) => {
        applySchedule(bundle, selectedEngine, cronExpression)
    }

    const getTriggerHumanReadable = (cronExpression) => {
        if (cronExpression) {
            try {
                return cronstrue.toString(cronExpression)
            } catch {
                return 'Invalid expression'
            }
        }

        return 'Unscheduled bundle'
    }

    return (
        <div className="bundle-runs-table p-mb-2">
            <Fieldset className='p-ml-0 p-mr-0 container' legend='Activity'>
                <div>
                    <span className="p-text-bold">Engine : </span>
                    <Dropdown
                        name='engine'
                        className='p-mr-2'
                        placeholder='engine'
                        value={selectedEngine}
                        options={availableEngines}
                        onChange={(e) => setSelectedEngine(e.value)}
                    />
                </div>

                <p>
                    <span className="p-text-bold">Actual trigger : </span>
                    {getTriggerHumanReadable(bundle['schedule-setup'].schedule)}
                </p>

                <Button
                    className='p-mr-2'
                    label='Launch' icon="pi pi-play" onClick={() => runBundle(bundle, selectedEngine)}
                    loading={runBundleLoading}
                />

                <Button
                    className='p-mr-2'
                    label='Trigger' icon="pi pi-clock" onClick={(e) => op.current.toggle(e)}
                    loading={scheduleBundleLoading}
                />

                <OverlayPanel ref={op} dismissable={false}>
                    <div className="p-field">
                        <label htmlFor="cron-expression" className="p-d-block">Cron expression</label>
                        <InputText
                            id="cron-expression"
                            className="p-d-block"
                            placeholder='* * * * *'
                            defaultValue={bundle['schedule-setup'].schedule}
                            autoFocus
                            onChange={(e) => setCronExpression(e.target.value)}
                        />
                    </div>
                    <p>{getTriggerHumanReadable(cronExpression)}</p>
                    <div className='p-display-flex p-jc-left'>
                        <Button
                            label="Cancel"
                            icon="pi pi-times"
                            className="p-button-sm p-button-danger p-button-outlined p-mr-2"
                            onClick={() => {
                                op.current.hide();
                                setCronExpression(bundle['schedule-setup'].schedule);
                            }}
                        />
                        <Button
                            label="Apply"
                            icon="pi pi-check"
                            className="p-button-sm"
                            disabled={getTriggerHumanReadable(cronExpression) === 'Invalid expression' ? true : false}
                            onClick={() => applyTrigger(cronExpression)}
                            loading={scheduleBundleLoading}
                        />
                    </div>
                </OverlayPanel>

                <DataTable ref={dt} value={bundleStatuses} paginator rows={4}
                    header={header} className="p-datatable p-mt-4"
                    selectionMode="single"
                    sortField='customStartTime'
                    sortOrder={-1}
                    globalFilter={globalFilter} emptyMessage="No runs found."
                >

                    <Column field="id" header="ID" body={idBodyTemplate} sortable />

                    <Column field="status" header="Status" body={statusBodyTemplate} sortable />

                    <Column field="step" header="Step" body={stepBodyTemplate} sortable />

                    <Column field="customStartTime" header="Start time" body={startTimeBodyTemplate} sortable />

                    <Column field="customCompletionTime" header="Completion time" body={completionTimeBodyTemplate} sortable />
                </DataTable>
            </Fieldset>
        </div>
    );
};

export default BundleRunsTable;
