//React imports
import React, { useEffect, useState } from 'react';
//---

//CSS imports
import './NewReleaseDialog.css'
//---

//PrimeReact imports
import { Dialog } from 'primereact/dialog';
//---

//Data requests imports
import {
    setLastRelease,
    getLastRelease
} from '../data/AppLocalData';
//---

const RELEASE = process.env.REACT_APP_RELEASE;

const NewReleaseDialog = () => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (isCurrentReleaseIsNewest()) {
                setIsOpen(true);
            }
        }

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const isCurrentReleaseIsNewest = () => {
        let lastRelease = getLastRelease();
        if (lastRelease && RELEASE) {
            let lastReleaseDate = lastRelease.substring(0, 10);
            let currentReleaseDate = RELEASE.substring(0, 10);

            if (currentReleaseDate > lastReleaseDate) {
                setLastRelease(RELEASE);

                return true;
            }
        } else if (RELEASE) {
            setLastRelease(RELEASE);

            return true;
        }

        return false;
    }

    return (
        <Dialog className='new-release-dialog' header="Nouvelle version" visible={isOpen} style={{ width: '50vw' }} onHide={() => setIsOpen(false)}>
            <h3 className='p-mt-0'>2023-09-28</h3>
            <h4>Nouveautés :</h4>
            <ul>
                <li>Amélioration du confort de navigation sur la plateforme.</li>
            </ul>
            <h4>Corrections :</h4>
            <ul>
                <li>Correction du mode "override" sur le writer PostgreSQL.</li>
                <li>Correction du champ "header" sur le reader CSV.</li>
            </ul>
            <h3 className='p-mt-0'>2023-09-26</h3>
            <h4>Nouveautés :</h4>
            <ul>
                <li>Ajout de l'archivage des tasks dans storage.</li>
            </ul>
            <h3 className='p-mt-0'>2023-09-01</h3>
            <h4>Nouveautés :</h4>
            <ul>
                <li>Compatibilité du data-explorer avec DuckDB sur S3.</li>
            </ul>
            <h3 className='p-mt-0'>2023-08-17</h3>
            <h4>Nouveautés :</h4>
            <ul>
                <li>Ajout de la section Test</li>
            </ul>
            <h3 className='p-mt-0'>2023-07-19</h3>
            <h4>Nouveautés :</h4>
            <ul>
                <li>Ajout du module Elementary</li>
            </ul>
            <h3 className='p-mt-0'>2023-07-06</h3>
            <h4>Nouveautés :</h4>
            <ul>
                <li>Intégration de l'assistant IA dans la fenêtre de requêtes personnalisées.</li>
            </ul>
            <h3 className='p-mt-0'>2023-06-27</h3>
            <h4>Nouveautés :</h4>
            <ul>
                <li>Simplification de la gestion des flows et artefacts : les flows ne sont plus stockés dans le dossier "flow", mais directement en tant qu'artefact.</li>
            </ul>
            <h3 className='p-mt-0'>2023-06-19</h3>
            <h4>Nouveautés :</h4>
            <ul>
                <li>Ajout d'une entrée dans le menu du data-explorer permettant de lancer des requêtes personnalisées.</li>
            </ul>

            <a href='https://datatask.notion.site/Release-notes-24929aa7a6ca4397aca3050eaaf07a14'
                rel='noreferrer' target='_blank' className='external-link'>
                Voir toutes les notes de versions
            </a>
        </Dialog>
    )
}

export default NewReleaseDialog;